import React from "react";
import { useHistory } from "react-router-dom";
import { useRequest } from "ahooks";
import { message, PageHeader, Spin } from "antd";
import { PageFunctionComponent } from "libs/interfaces/page-function-component";
import { useSetTitleEffect } from "store/hooks";
import useGoBack from "hooks/use-go-back";
import ContentLayout from "components/layouts/content.layout";
import Breadcrumbs from "components/common/breadcrumbs";



import SettingsPage from "pages/settings/settings.page";
import { ApiDriverHttpError } from "libs/common/api-driver/api-driver";

import CurrentIssuesSettingsPage from "../current-issues/currentIssues.page";
import { currentIssuesService } from "services";
import { CurrentIssuesService } from "services/current-issues/current-issues.service";
import CurrentIssuesForm from "components/forms/CurrentIssuesForm";


const createCurrentIssues: CurrentIssuesService["createOne"] = (dto) => currentIssuesService.createOne(dto);

const AddCurrentIssuesSettingsPage: PageFunctionComponent = () => {
  useSetTitleEffect([SettingsPage.label, AddCurrentIssuesSettingsPage.label]);

  const goBack = useGoBack();

  const history = useHistory();

  const { run, loading } = useRequest(createCurrentIssues, {
    manual: true,
    onSuccess: (data) => {
      if (data) {
        console.log("ДАННЫЕ", data)
        history.push(`${SettingsPage.path}${CurrentIssuesSettingsPage.pathCreator(data.id)}`, {
          canGoBack: true,
        });
      }
    },

    onError: (e, params) => {
      const [dto] = params;
      const { name } = dto;
      if (e instanceof ApiDriverHttpError) {
        if (e.status === 409) {
          message.error(`Данный вопрос "${name}" уже существует!`);
        }
      }
    },
  });

  return (
    <ContentLayout>
      <Breadcrumbs items={[SettingsPage.label, AddCurrentIssuesSettingsPage.label]} />
      <PageHeader title={AddCurrentIssuesSettingsPage.label} onBack={goBack}>
        <Spin spinning={loading}>
          <CurrentIssuesForm title="" onFinish={run} />
        </Spin>
      </PageHeader>
    </ContentLayout>
  );
};

AddCurrentIssuesSettingsPage.path = "/add-current-issues";
AddCurrentIssuesSettingsPage.label = "Добавить актуальный вопрос";

export default AddCurrentIssuesSettingsPage;
