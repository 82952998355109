import React, { useEffect, useState } from "react";
import { Form, Input, Descriptions, Button, Space, Switch, Select, DatePicker, } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { Store } from "antd/es/form/interface";
import { CurrentIssuesService } from "services/current-issues/current-issues.service";
import { CurrentIssues } from "services/current-issues/models/current-issues";
import { CurrentIssuesUpsertDto } from "services/current-issues/dtos/current-issues-upsert-dto.type";
import { displayDateTime } from "libs/helpers/display-date-time";
import { noOp } from "libs/helpers/no-op";
// import { BranchService } from "services/branch/branch.service";
import ApiDriver from "libs/common/api-driver/api-driver";
import { apiDriver } from "api-driver.instance";
import moment from "moment";

export type CurrentIssuesFormDto = CurrentIssuesUpsertDto;

export interface CurrentIssuesFormProps {
  form?: FormInstance<CurrentIssuesFormDto>;
  onFinish?: (dto: CurrentIssuesUpsertDto, entityToUpdate?: CurrentIssues) => void;
  entityToUpdate?: CurrentIssues;
  initialValues?: Partial<CurrentIssuesFormDto>;
  disabledFields?: { [key in keyof CurrentIssuesFormDto]?: boolean };
  title?: string;
}
// export interface Branch {
//     id: string;
//     name: string;
//     }
// const ap:ApiDriver=apiDriver
// ap.accessToken
// const branchService = new BranchService(apiDriver)



const COLUMN = { xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 };
// const { Option } = Select;
const CurrentIssuesForm: React.FC<CurrentIssuesFormProps> = (props) => {
  const {
    form: formProp,
    onFinish = noOp,
    entityToUpdate,
    initialValues: initialValuesProp,
    title = "Актуальный вопрос",
    disabledFields,
  } = props;


  // const [branches, setBranches] = useState<Branch[]>([]);
  // useEffect(() => {
  //     const fetchBranches = async () => {
  //      try {
  //         const response = await branchService.findAll();
  //         const branchData:any = Array.isArray(response) ? response : response.data;
  //         setBranches(branchData);
  //      } catch (error) {
  //         console.error("Ошибка при получении филиалов:", error);
  //      }
  //     };
  
  //     fetchBranches();
  // }, []);
  

  const [form] = Form.useForm(formProp);

  const reset = React.useCallback(() => {
    form.resetFields();
  }, [form]);

  const initialValues: Store | undefined = React.useMemo(() => {
    const iV: Store = {};

    // if (entityToUpdate) {
    //   Object.assign(iV, entityToUpdate);
    // }

    // if (initialValuesProp) {
    //   Object.assign(iV, initialValuesProp);
    // }

    if (entityToUpdate) {
      const { createDate,deleteDate, ...rest } = entityToUpdate;
      Object.assign(iV, { ...rest });
      if (createDate instanceof Date) {
        Object.assign(iV, { createDate: moment(createDate) });
      }
      if (deleteDate instanceof Date) {
        Object.assign(iV, { deleteDate: moment(deleteDate) });
      }
    }

    if (initialValuesProp) {
      const { createDate,deleteDate, ...rest } = initialValuesProp;
      Object.assign(iV, { ...rest });
      if (createDate instanceof Date) {
        Object.assign(iV, { createDate: moment(createDate) });
      }
      if (deleteDate instanceof Date) {
        Object.assign(iV, { deleteDate: moment(deleteDate) });
      }
    }

    return iV;
  }, [entityToUpdate, initialValuesProp]);

  const handleFinish = React.useCallback(
    (dto: CurrentIssuesFormDto) => {
        console.log("Данные",dto, entityToUpdate)
      onFinish(dto, entityToUpdate);
    },
    [onFinish, entityToUpdate]
  );

  return (
    <Form form={form} initialValues={initialValues} onFinish={handleFinish}>
      <Descriptions title={title} column={COLUMN}>
        <Descriptions.Item label={<strong>Id</strong>}>{entityToUpdate?.id}</Descriptions.Item>
        <Descriptions.Item>
          <Form.Item
            id="name"
            name="name"
            label={<strong>Название (вопрос)</strong>}
            style={{ marginBottom: 0, width: "100%" }}
            rules={[
              { required: true, message: "Это поле должно быть заполнено." },
              {
                type: "string",
              
              },
            ]}
          >
            <Input.TextArea  disabled={disabledFields?.name} />
          </Form.Item>
        </Descriptions.Item>

        <Descriptions.Item>
          <Form.Item
            id="createDate"
            name="createDate"
            label={<strong>Дата начала действия</strong>}
            style={{ marginBottom: 0, width: "100%" }}
            rules={[{ required: true, message: "Это поле должно быть заполнено." }]}
          >
            <DatePicker showTime disabled={disabledFields?.createDate} />
          </Form.Item>
        </Descriptions.Item>

        
        <Descriptions.Item>
          <Form.Item
            id="deleteDate"
            name="deleteDate"
            label={<strong>Дата окончания действия</strong>}
            style={{ marginBottom: 0, width: "100%" }}
            rules={[{ required: true, message: "Это поле должно быть заполнено." }]}
          >
            <DatePicker showTime disabled={disabledFields?.deleteDate} />
          </Form.Item>
        </Descriptions.Item>



      </Descriptions>
      <Form.Item noStyle>
        <Space style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="primary" size="large" htmlType="submit">
            Сохранить
          </Button>
          <Button type="text" size="large" onClick={reset}>
            Сбросить
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default CurrentIssuesForm;
