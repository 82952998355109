import { Type } from "class-transformer";
import { CurrentIssues } from "services/current-issues/models/current-issues";
import { Branch } from "services/branch/models/branch";

export class BranchQuestions {
  id!: string;
  currentIssuesId!: string;
  branchId!: string;

  @Type(() => CurrentIssues)
  currentIssues?: CurrentIssues;

  @Type(()=>Branch)
  branch?:Branch;
}
