import { Type } from "class-transformer";
import { Review } from "../review.service";
import { ReviewTagType } from "services/review-tag-type/models/review-tag-type";
import { PartialBy } from "libs/types/partial-by.type";

export class ReviewQuestions {
  id: string;
  reviewId: string;
  currentIssuesId: string;
  answersId:string
answerId?:string;

  @Type(() => Review)
  review?: Review;
  currentIssues: any;
  answer: any;
  

 
}
export type QuestionsPartialById = PartialBy<ReviewQuestions, "id">;
