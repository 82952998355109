import React from "react";
import { ArrowRightOutlined, EditOutlined, PhoneOutlined, MailOutlined } from "@ant-design/icons";
import { gold } from "@ant-design/colors";
import { TableState } from "libs/interfaces/table-state.interface";
import { Review } from "services/review/review.service";
import { SortOrder, ColumnsType } from "antd/es/table/interface";
import { getColumnSearchProps } from "components/common/table-column-search-filter/table-column-search-filter";
import Highlighter from "react-highlight-words";
import { displayDateTime } from "libs/helpers/display-date-time";
import ReviewsPage from "pages/reviews/reviews.page";
import ReviewCardPage from "../../card/card.page";
import { Link } from "react-router-dom";
import ReviewStatus from "components/common/review-status";
import EditReviewCardPage from "../../edit-card/edit-card.page";
import { ReviewStatusEnum } from "libs/enums/review-status.enum";
import { User } from "services/user/models/user";

function getColumns(tableState: TableState<Review>, operators: User[]): ColumnsType<Review> {
  let createdAtSortorder: SortOrder = null;
  let updatedAtSortOrder: SortOrder = null;
    console.log("TABLE STATE", tableState)
    
  if (Array.isArray(tableState.sorter)) {
    //...
  } else {
    createdAtSortorder =
      tableState.sorter.field === "createdAt" && tableState.sorter.order
        ? tableState.sorter.order
        : null;
    updatedAtSortOrder =
      tableState.sorter.field === "updatedAt" && tableState.sorter.order
        ? tableState.sorter.order
        : null;
  }
  console.log("tableState",createdAtSortorder, updatedAtSortOrder, tableState)
  return [
    {
      title: "Оператор",
      dataIndex: "operator",
      key: "operator",
      render: (_: any, record) => (
        <React.Fragment>
          {record.operatorUser?.surname} {record.operatorUser?.firstName}
        </React.Fragment>
      ),
      filters: operators.map((operator) => ({
        text: `${operator.surname} ${operator.firstName}`,
        value: operator.id,
      })),
      filteredValue: tableState.filters.operator || null,
      ellipsis: true,
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      render: (_: any, record) => <ReviewStatus status={record.status} />,
      filters: [
        {
          text: <ReviewStatus status={ReviewStatusEnum.DRAFT} />,
          value: ReviewStatusEnum.DRAFT,
        },
        {
          text: <ReviewStatus status={ReviewStatusEnum.REQUIRE_CHECK} />,
          value: ReviewStatusEnum.REQUIRE_CHECK,
        },
      ],
      filteredValue: tableState.filters.status || null,
      ellipsis: true,
    },
    {
      title: "Город",
      dataIndex: "city",
      key: "city",
      render: (_: any, record) => record?.branch?.city?.name,
      ellipsis: true,
    },
    {
      title: "Филиал",
      dataIndex: "branchId",
      key: "branchId",
      render: (_: any, record) => record?.branch?.name,
      // filters: branches.map((branch) => ({
      //   text: branch.name,
      //   value: branch.id,
      // })),
      filteredValue: tableState.filters.branchId || null,
      ellipsis: true,
    },
    {
      title: "Клиент",
      dataIndex: "clientDisplayName",
      key: "clientDisplayName",
      ...getColumnSearchProps("clientDisplayName"),
      filteredValue: tableState.filters.clientDisplayName || null,
      render: (_: any, record) =>
        tableState.filters.clientDisplayName ? (
          <Highlighter
            highlightStyle={{ backgroundColor: gold[3], padding: 0 }}
            searchWords={tableState.filters.clientDisplayName.map((rStr) => rStr.toString().trim())}
            autoEscape
            textToHighlight={record.clientDisplayName}
          />
        ) : (
          record.clientDisplayName
        ),
      ellipsis: true,
    },
    {
      title: "Контакты клиента",
      dataIndex: "clientContacts",
      key: "clientContacts",
      ...getColumnSearchProps("clientContacts", "Телефон или email клиента"),
      filteredValue: tableState.filters.clientContacts || null,
      render: (_: any, record) => {
        return (
          <div>
            {record.clientPhone ? (
              <div>
                <PhoneOutlined />{" "}
                <span>
                  {tableState.filters.clientContacts ? (
                    <Highlighter
                      highlightStyle={{ backgroundColor: gold[4], padding: 0 }}
                      searchWords={tableState.filters.clientContacts.map((rStr) =>
                        rStr.toString().trim()
                      )}
                      autoEscape
                      textToHighlight={record.clientPhone}
                    />
                  ) : (
                    record.clientPhone
                  )}
                </span>
              </div>
            ) : null}
            {record.clientEmail ? (
              <div>
                <MailOutlined />{" "}
                <span>
                  {tableState.filters.clientContacts ? (
                    <Highlighter
                      highlightStyle={{ backgroundColor: gold[4], padding: 0 }}
                      searchWords={tableState.filters.clientContacts.map((rStr) =>
                        rStr.toString().trim()
                      )}
                      autoEscape
                      textToHighlight={record.clientEmail}
                    />
                  ) : (
                    record.clientEmail
                  )}
                </span>
              </div>
            ) : null}
          </div>
        );
      },
      ellipsis: true,
    },
    {
      title: "Дата создания",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: Date) => displayDateTime(createdAt),
      sorter: true,
      sortOrder: createdAtSortorder,
      ellipsis: true,
    },
    {
      title: "Дата обновления",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (updatedAt: Date) => displayDateTime(updatedAt),
      sorter: true,
      sortOrder: updatedAtSortOrder,
      sortDirections: ["descend"],
      ellipsis: true,
    },
    {
      title: "",
      key: "action1",
      render: (text: any, record) => (
        <Link
          to={{
            pathname: `${ReviewsPage.path}${ReviewCardPage.pathCreator(record.id)}`,
            state: {
              canGoBack: true,
            },
          }}
        >
          <ArrowRightOutlined />
        </Link>
      ),
      fixed: "right",
      align: "center",
    },
    {
      title: "",
      key: "action2",
      render: (text: any, record) => (
        <Link
          to={{
            pathname: `${ReviewsPage.path}${EditReviewCardPage.pathCreator(record.id)}`,
            state: {
              canGoBack: true,
            },
          }}
        >
          <EditOutlined />
        </Link>
      ),
      fixed: "right",
      align: "center",
    },
  ];
}

export default getColumns;
