import { Type } from "class-transformer";
import { ReviewStatusEnum } from "libs/enums/review-status.enum";
import { ReviewChatMessage } from "./review-chat-message.model";
import { ReviewClientCall } from "./review-client-call.model";
import { ReviewClientEmail } from "./review-client-email";
import { ReviewDestination } from "./review-destination.model";
import { ReviewServiceOrder } from "../../models/review-service-order.model";
import { ReviewWebLink } from "./review-web-link.model";
import { ReviewStatus } from "./review-status.model";
import { ReviewTag } from "./review-tag.model";
import { User } from "services/user/models/user";
import { Branch } from "services/branch/models/branch";
import { ReviewQuestions } from "./review-questions.model";

export class Review {
  id: string;
  branchId?: string;
  operatorUserId?: string;
  status: ReviewStatusEnum;
  statusUserId?: string;

  @Type(() => Date)
  statusUpdatedAt: Date;

  rate?: number | null;
  content: string;
  comment?: string;
  clientDisplayName: string;
  clientPhone?: string;
  clientEmail?: string;

  @Type(() => Date)
  createdAt: Date;

  @Type(() => Date)
  updatedAt: Date;

  @Type(() => Branch)
  branch?: Branch;

  @Type(() => User)
  operatorUser?: User;

  @Type(() => User)
  statusUser?: User;

  @Type(() => ReviewChatMessage)
  reviewChatMessages?: ReviewChatMessage[];

  @Type(() => ReviewClientCall)
  reviewClientCalls?: ReviewClientCall[];

  @Type(() => ReviewClientEmail)
  reviewClientEmails?: ReviewClientEmail[];

  @Type(() => ReviewDestination)
  reviewDestinations?: ReviewDestination[];

  @Type(() => ReviewServiceOrder)
  reviewServiceOrders?: ReviewServiceOrder[];

  @Type(() => ReviewWebLink)
  reviewWebLinks?: ReviewWebLink[];

  @Type(() => ReviewStatus)
  reviewStatuses?: ReviewStatus[];

  @Type(() => ReviewTag)
  reviewTags?: ReviewTag[];
  
  @Type(()=>ReviewQuestions)
  reviewQuestions?:ReviewQuestions[];
}
