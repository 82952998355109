import React from "react";
import { Link } from "react-router-dom";
import { Checkbox, Table, TableProps } from "antd";
import { ColumnsType, SorterResult, SortOrder } from "antd/es/table/interface";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { displayDateTime } from "libs/helpers/display-date-time";
import { displayTotalRows } from "libs/helpers/display-total-rows";
import CurrentIssuesSettingsPage from "pages/settings/pages/current-issues/currentIssues.page";
import { ArrowRightOutlined } from "@ant-design/icons";
import SettingsPage from "pages/settings/settings.page";
import { CurrentIssues } from "services/current-issues/models/current-issues";

export interface CurrentIssuesTableProps {
  tableProps: TableProps<CurrentIssues>;
  sorter: SorterResult<CurrentIssues> | SorterResult<CurrentIssues>[];
  size?: SizeType;
}

const getColumns: (sorter: SorterResult<CurrentIssues> | SorterResult<CurrentIssues>[]) => ColumnsType<CurrentIssues> = (
  sorter
) => {
  let name: SortOrder = null;
  let responseType: SortOrder = null;
  let branchName: SortOrder = null;
  let createByUserId: SortOrder = null;
  let createDate: SortOrder = null;
  let deleteDate: SortOrder = null;
  
  if (Array.isArray(sorter)) {
    // multiple sorter case
  } else {
    if (sorter.field === "name" && sorter.order) {
      name = sorter.order;
    } else if (sorter.field === "responseType" && sorter.order) {
        responseType = sorter.order;
    } else if (sorter.field === "branchName" && sorter.order) {
        branchName = sorter.order;
    } else if (sorter.field === "createByUserId" && sorter.order) {
        createByUserId = sorter.order;
    } else if (sorter.field === "createDate" && sorter.order) {
        createDate = sorter.order;
    } else if (sorter.field === "deleteDate" && sorter.order) {
        deleteDate = sorter.order;
    }
  }

  return [
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
      sorter: true,
      sortOrder: name,
      ellipsis: true,
      render: (_: any, record) => record.name, 
    },
    // {
    //   title: "Тип вопроса",
    //   dataIndex: "responseType",
    //   key: "responseType",
    //   sorter: true,
    //   sortOrder: responseType,
    //   ellipsis: true,
    //   render: (_: any, record) => record.responseType,
    // },
    // {
    //   title: "Филиал",
    //   dataIndex: "branchName",
    //   key: "branchName",
    //   sorter: true,
    //   sortOrder: branchName,
    //   ellipsis: true,
    //   render: (_: any, record) => record.branchName,
    // },
    {
      title: "Дата начала действия",
      dataIndex: "createDate",
      key: "createDate",
      sorter: true,
      sortOrder: createDate,
      ellipsis: true,
      render: (value: Date) => displayDateTime(value),
    },
    {
      title: "Дата окончания действия",
      dataIndex: "deleteDate",
      key: "deleteDate",
      sorter: true,
      sortOrder: deleteDate,
      ellipsis: true,
      render: (value: Date) => displayDateTime(value),
    },
    {
      title: "",
      dataIndex: "currentIssues",
      key: "currentIssues",
      render: (_: any, record) => (
        <Link
          to={{
            pathname: `${SettingsPage.path}${CurrentIssuesSettingsPage.pathCreator(record.id)}`,
            state: { canGoBack: true },
          }}
        >
          <ArrowRightOutlined />
        </Link>
      ),
      align: "center",
      fixed: "right",
      ellipsis: true,
    },
  ];
 
};

const CurrentIssuesTable: React.FC<CurrentIssuesTableProps> = (props) => {
  const { tableProps, sorter, size } = props;

  const columns = React.useMemo(() => getColumns(sorter), [sorter]);

  return (
    <div>
      <Table
        columns={columns}
        rowKey="id"
        {...tableProps}
        size={size}
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
          showTotal: displayTotalRows,
          position: ["topRight", "bottomRight"],
        }}
        tableLayout="auto"
        scroll={{ x: 1280 }}
      />
    </div>
  );
};

export default CurrentIssuesTable;
