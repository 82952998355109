import React from "react";
import { Table, TableProps } from "antd";
import { ColumnsType, SorterResult, SortOrder } from "antd/es/table/interface";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { displayDateTime } from "libs/helpers/display-date-time";
import { displayTotalRows } from "libs/helpers/display-total-rows";
import { BranchQuestions } from "services/branch-questions/models/branch-questions";

export interface BranchQuestionsTableProps {
  tableProps: TableProps<BranchQuestions>;
  sorter: SorterResult<BranchQuestions> | SorterResult<BranchQuestions>[];
  size?: SizeType;
  rightExtra?: ColumnsType<BranchQuestions>;
}

export interface BranchQuestionsTableGetColumnsParams {
  sorter: BranchQuestionsTableProps["sorter"];
  rightExtra?: BranchQuestionsTableProps["rightExtra"];
}

const getColumns: (params: BranchQuestionsTableGetColumnsParams) => ColumnsType<BranchQuestions> = (
  params
) => {
  const { sorter, rightExtra } = params;

  
  let createdAtSortOrder: SortOrder = null;

//   if (Array.isArray(sorter)) {
//     // multiple sorter case
//   } else {
//      if (sorter.field === "createdAt" && sorter.order) {
//       createdAtSortOrder = sorter.order;
//     }
//   }

  const columns: ColumnsType<BranchQuestions> = [
    {
      title: "Филиал",
      dataIndex: "branch",
      key: "branch",
      ellipsis: true,
      render: (_: any, record) => {
        if (record.branch) {
          return record.branch.name;
        
        }
        console.log("Данные",record)
        return record.branchId;
      },
    }
    
  ];

  if (rightExtra) {
    columns.push(...rightExtra);

  }

  return columns;
};

const BranchQuestionsTable: React.FC<BranchQuestionsTableProps> = (props) => {
  const { tableProps, sorter, size, rightExtra } = props;

  const columns = React.useMemo(() => getColumns({ sorter, rightExtra }), [sorter, rightExtra]);

  return (
    <div>
      <Table
        columns={columns}
        rowKey="id"
        {...tableProps}
        size={size}
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
          showTotal: displayTotalRows,
          position: ["topRight", "bottomRight"],
        }}
        tableLayout="auto"
        scroll={{ x: 1280 }}
      />
    </div>
  );
};

export default BranchQuestionsTable;
