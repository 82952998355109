import React from "react";
import { Form, Descriptions, Button, Space, Input, Select } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { Store } from "antd/es/form/interface";
import { Answers } from "services/answers/models/answers";
import { AnswersUpsertDto } from "services/answers/dtos/answers-upsert-dto.type";
import { displayDateTime } from "libs/helpers/display-date-time";
import { noOp } from "libs/helpers/no-op";
import CurrentIssuesSelect from "components/selects/CurrentIssuesSelect";



export type AnswersFormDto = AnswersUpsertDto;

export interface AnswersFormProps {
  form?: FormInstance<AnswersFormDto>;
  onFinish?: (dto: AnswersUpsertDto, entityToUpdate?: Answers) => void;
  entityToUpdate?: Answers;
  initialValues?: Partial<AnswersFormDto>;
  disabledFields?: { [key in keyof AnswersFormDto]?: boolean };
  title?: string;
}

const COLUMN = { xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 };
const { Option } = Select;
const AnswersForm: React.FC<AnswersFormProps> = (props) => {
  const {
    form: formProp,
    onFinish = noOp,
    entityToUpdate,
    initialValues: initialValuesProp,
    title = "Ответы на вопрос",
    disabledFields: disabledFieldsProp,
  } = props;

  const [form] = Form.useForm(formProp);

//   const initialValues: Store | undefined = React.useMemo(() => {
//     const iV: Store = {};

//     if (entityToUpdate) {
//       Object.assign(iV, entityToUpdate);
//     }

//     if (initialValuesProp) {
//       Object.assign(iV, initialValuesProp);
//     }

//     return iV;
//   }, [entityToUpdate, initialValuesProp]);

//   const [disabledFields, setDisabledFields] = React.useState<{
//     action: boolean;
//   }>({
//     action: !initialValues?.subject,
//   });



const initialValues: Store | undefined = React.useMemo(() => {
    const iV: Store = {
      isHead: false,
    };

    if (entityToUpdate) {
      Object.assign(iV, entityToUpdate);
    }

    if (initialValuesProp) {
      Object.assign(iV, initialValuesProp);
    }

    return iV;
  }, [entityToUpdate, initialValuesProp]);

  const reset = React.useCallback(() => {
    form.resetFields();
   
  }, [form, initialValues]);


  const handleFinish = React.useCallback(
    (dto: AnswersFormDto) => {
      onFinish(dto, entityToUpdate);
    },
    [onFinish, entityToUpdate]
  );

//   const handleSubjectSelectChange: (value: string) => void = React.useCallback(
//     (value) => {
//       if (value) {
//         setDisabledFields({ action: false });
//       } else {
//         setDisabledFields({ action: true });
//       }
//       form.setFieldsValue({
//         action: undefined,
//       });
//     },
//     [setDisabledFields, form]
//   );

//   const handleSubjectSelectClear = React.useCallback(
//     () => setDisabledFields({ action: true }),
//     [setDisabledFields]
//   );

  return (
    <Form form={form} initialValues={initialValues} onFinish={handleFinish}>
      <Descriptions title={title} column={COLUMN}>
        <Descriptions.Item label={<strong>Id</strong>}>{entityToUpdate?.id}</Descriptions.Item>
        <Descriptions.Item>
          <Form.Item
            id="currentIssuesId"
            name="currentIssuesId"
            label={<strong>Вопрос</strong>}
            style={{ marginBottom: 0, width: "100%" }}
            rules={[{ required: true, message: "Это поле должно быть заполнено." }]}
          >
            <CurrentIssuesSelect disabled={disabledFieldsProp?.currentIssuesId} />
          </Form.Item>
        </Descriptions.Item>
        

        <Descriptions.Item>
          <Form.Item
            id="answer"
            name="answer"
            label={<strong>Ответ</strong>}
            style={{ marginBottom: 0, width: "100%" }}
            rules={[
              { required: false, message: "Это поле должно быть заполнено." },
              {
                type: "string",
              
              },
            ]}
          >
            <Input.TextArea  disabled={disabledFieldsProp?.answer} />
          </Form.Item>
        </Descriptions.Item>

        <Descriptions.Item>
            <Form.Item
                id="typeAnswer"
                name="typeAnswer"
                label={<strong>Положительный/отрицательный</strong>}
                style={{ marginBottom: 0, width: "100%" }}
                rules={[
                { required: false, message: "Это поле должно быть заполнено." },
                {
                    type: "string",
                },
                ]}
            >
                <Select disabled={disabledFieldsProp?.typeAnswer}>
                <Option value="Положительный">Положительный</Option>
                <Option value="Отрицательный">Отрицательный</Option>
                </Select>
            </Form.Item>
            </Descriptions.Item>

        <Descriptions.Item label={<strong>Дата создания</strong>}>
          {displayDateTime(entityToUpdate?.createdAt)}
        </Descriptions.Item>
      </Descriptions>
      <Form.Item noStyle>
        <Space style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="primary" size="large" htmlType="submit">
            Сохранить
          </Button>
          <Button type="text" size="large" onClick={reset}>
            Сбросить
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default AnswersForm;
