import { Data, Params, Service } from "ahooks/lib/useAntdTable/types";
import { CreateQueryParams, RequestQueryBuilder } from "@nestjsx/crud-request";
import { plainToClass } from "class-transformer";
import ApiDriver from "libs/common/api-driver/api-driver";
import { convertTableSorterToQuerySorter } from "libs/helpers/convert-table-sorter-to-query-sorter";
import { PagiantedResponse } from "services/interfaces/paginated-response.interface";
import { BranchQuestionsUpsertDto } from "./dtos/branch-questions-upsert-dto.type";
import { BranchQuestions } from "./models/branch-questions";

export class BranchQuestionsService {
//   static deleteOne(currentIssuesId: string, id: string): Promise<Answers> {
//       throw new Error("Method not implemented.");
//   }
  constructor(private readonly apiDriver: ApiDriver) {}

  async findAll(currentIssuesId: BranchQuestions["currentIssuesId"], params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    let response = await this.apiDriver.get<PagiantedResponse<BranchQuestions> | BranchQuestions[]>(
      `/current-issues/${currentIssuesId}/branch-questions`,
      { params: queryParams }
    );
    if (Array.isArray(response)) {
      response = plainToClass(BranchQuestions, response);
    } else {
      response.data = plainToClass(BranchQuestions, response.data);
    }
    return response;
  }

  async findOne(
    currentIssuesId:BranchQuestions["currentIssuesId"],
    id: BranchQuestions["id"],
    params: CreateQueryParams = {}
  ) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    const response = await this.apiDriver.get<BranchQuestions>(`current-issues/${currentIssuesId}/branch-questions/${id}`, {
      params: queryParams,
    });
    return plainToClass(BranchQuestions, response);
  }

  async createOne(currentIssuesId: BranchQuestions["currentIssuesId"], dto:BranchQuestionsUpsertDto) {
    const role = await this.apiDriver.post<BranchQuestions>(`current-issues/${currentIssuesId}/branch-questions`, dto);
    return plainToClass(BranchQuestions, role);
  }

  async updateOne(currentIssuesId: BranchQuestions["currentIssuesId"], id: BranchQuestions["id"], dto: BranchQuestionsUpsertDto) {
    const updated = await this.apiDriver.patch<BranchQuestions>(
      `/current-issues/${currentIssuesId}/branch-questions/${id}`,
      dto
    );
    return plainToClass(BranchQuestions, updated);
  }

  async deleteOne(
    currentIssuesId: BranchQuestions["currentIssuesId"],
    id: BranchQuestions["id"],
    params: CreateQueryParams = {}
  ) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    const deleted = await this.apiDriver.delete<BranchQuestions>(`/current-issues/${currentIssuesId}/branch-questions/${id}`, {
      params: queryParams,
    });
    return plainToClass(BranchQuestions, deleted);
  }





  getBranchQuestionsTableDataGetter(currentIssuesId: BranchQuestions["currentIssuesId"]): Service<Data, Params> {
    return async (paginatedParams, formData: Record<string, unknown>) => {
      const tableQuerySorter = convertTableSorterToQuerySorter<BranchQuestions>(paginatedParams.sorter);

      const answers = await this.findAll(currentIssuesId,{
        page: paginatedParams.current,
        limit: paginatedParams.pageSize,
        sort: tableQuerySorter
          ? [tableQuerySorter, { field: "id", order: "ASC" }]
          : { field: "id", order: "ASC" },
        join: [["currentIssues"], ["branch"]],
      });

      if (Array.isArray(answers)) {
        return {
          list: answers,
          total: answers.length,
        };
      } else {
        return {
          list: answers.data,
          total: answers.total,
        };
      }
    };
  }
}
