import React from "react";
import { Descriptions } from "antd";
import { CurrentIssues } from "services/current-issues/models/current-issues";
import { displayDateTime } from "libs/helpers/display-date-time";

export interface CurrentIssuesDescriptionProps {
  data: CurrentIssues;
  title?: string;
}

const COLUMN = { xxl: 3, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 };

const CurrentIssuesDescription: React.FC<CurrentIssuesDescriptionProps> = (props) => {
  const { data, title = "Вопрос" } = props;

  return (
    <Descriptions title={title} column={COLUMN}>
      <Descriptions.Item label={<strong>Id</strong>}>{data.id}</Descriptions.Item>
      <Descriptions.Item label={<strong>Название</strong>}>{data.name}</Descriptions.Item>
      <Descriptions.Item label={<strong>Дата начала действия</strong>}>
        {displayDateTime(data.createDate)}
      </Descriptions.Item>
      <Descriptions.Item label={<strong>Дата окончания действия</strong>}>
        {displayDateTime(data.deleteDate)}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default CurrentIssuesDescription;
