import React from "react";
import { Table, TableProps } from "antd";
import { ColumnsType, SorterResult, SortOrder } from "antd/es/table/interface";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { displayDateTime } from "libs/helpers/display-date-time";
import { displayTotalRows } from "libs/helpers/display-total-rows";
import { Answers } from "services/answers/models/answers";

export interface AnswersTableProps {
  tableProps: TableProps<Answers>;
  sorter: SorterResult<Answers> | SorterResult<Answers>[];
  size?: SizeType;
  rightExtra?: ColumnsType<Answers>;
}

export interface AnswersTableGetColumnsParams {
  sorter: AnswersTableProps["sorter"];
  rightExtra?: AnswersTableProps["rightExtra"];
}

const getColumns: (params: AnswersTableGetColumnsParams) => ColumnsType<Answers> = (
  params
) => {
  const { sorter, rightExtra } = params;

  
  let createdAtSortOrder: SortOrder = null;

  if (Array.isArray(sorter)) {
    // multiple sorter case
  } else {
     if (sorter.field === "createdAt" && sorter.order) {
      createdAtSortOrder = sorter.order;
    }
  }

  const columns: ColumnsType<Answers> = [
    {
      title: "Ответ",
      dataIndex: "answer",
      key: "answer",
      ellipsis: true,
      render: (_: any, record) => {
        if (record.answer) {
          return record.answer;
        }
        return record.answer;
      },
    },
    {
      title: "Положительный/отрицательный",
      dataIndex: "typeAnswer",
      key: "typeAnswer",
      ellipsis: true,
      render: (_: any, record) => {
        if (record.typeAnswer) {
          return record.typeAnswer;
        }
        return record.typeAnswer;
      },
    },
    {
      title: "Дата создания",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      sortOrder: createdAtSortOrder,
      ellipsis: true,
      render: (value: Date) => displayDateTime(value),
    },
  ];

  if (rightExtra) {
    columns.push(...rightExtra);
  }

  return columns;
};

const AnswersTable: React.FC<AnswersTableProps> = (props) => {
  const { tableProps, sorter, size, rightExtra } = props;

  const columns = React.useMemo(() => getColumns({ sorter, rightExtra }), [sorter, rightExtra]);

  return (
    <div>
      <Table
        columns={columns}
        rowKey="id"
        {...tableProps}
        size={size}
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
          showTotal: displayTotalRows,
          position: ["topRight", "bottomRight"],
        }}
        tableLayout="auto"
        scroll={{ x: 1280 }}
      />
    </div>
  );
};

export default AnswersTable;
