import React from "react";
import { Descriptions } from "antd";
import { ServiceOrder } from "services/service-order.service";
import { displayDateTime } from "libs/helpers/display-date-time";
import { BlacklistClient } from "services/blacklist-client.service";
import "./service-order-descriptions.css";

export interface ServiceOrderDescriptionsProps {
  serviceOrder: ServiceOrder;
  blacklistClient?: BlacklistClient;
}

function ServiceOrderDescriptions(props: ServiceOrderDescriptionsProps) {
  const { serviceOrder, blacklistClient } = props;

  return (
    <Descriptions
      column={{ xxl: 2, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
      bordered
      style={{ wordBreak: "normal" }}
    >
      <Descriptions.Item label="Номер">{serviceOrder.number}</Descriptions.Item>
      <Descriptions.Item label="Филиал">{serviceOrder.branchName}</Descriptions.Item>
      <Descriptions.Item label="Клиент">
        {blacklistClient ? (
          <div>
            <div>{serviceOrder.clientDisplayName}</div>
            <div className="service-order-descriptions__blacklist-client">
              <div>В черном списке от {displayDateTime(blacklistClient.createdAt)}</div>
              {blacklistClient.reason && <div>Причина: {blacklistClient.reason}</div>}
            </div>
          </div>
        ) : (
          serviceOrder.clientDisplayName
        )}
      </Descriptions.Item>
      <Descriptions.Item label="Телефон клиента">{serviceOrder.clientPhone}</Descriptions.Item>
      <Descriptions.Item label="Автомобиль">
        {[serviceOrder.carManufacturer, serviceOrder.carModel, serviceOrder.carManufactureYear]
          .filter((el) => !!el)
          .join(" ")}
      </Descriptions.Item>
      <Descriptions.Item label="Побег автомобиля">
        {serviceOrder.carMileage ? `${serviceOrder.carMileage} км.` : null}
      </Descriptions.Item>
      <Descriptions.Item label="VIN автомобиля">{serviceOrder.carVin}</Descriptions.Item>
      <Descriptions.Item label="Автомобильный номер">
        {serviceOrder.carLicensePlate}
      </Descriptions.Item>
      <Descriptions.Item label="Дата открытия">
        {displayDateTime(serviceOrder.openedAt)}
      </Descriptions.Item>
      <Descriptions.Item label="Дата закрытия">
        {displayDateTime(serviceOrder.closedAt)}
      </Descriptions.Item>
     
      {serviceOrder.serviceOrderWorks && (
        <Descriptions.Item span={2} label="Работы">
          <table>
            <tbody>
              {serviceOrder.serviceOrderWorks.map((serviceOrderWork) => (
                <tr key={`serviceOrder_${serviceOrder.id}_serviceOrderWork_${serviceOrderWork.id}`}>
                  <td>{serviceOrderWork.name}</td>
                  <td>{serviceOrderWork.quantity} шт.</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Descriptions.Item>
      )}
      {serviceOrder.recommendations && (
        <Descriptions.Item span={2} label="Рекомендации мастера" style={{ wordBreak: "unset" }}>
          <pre style={{ whiteSpace: "pre-wrap", marginBottom: 0 }}>
            {serviceOrder.recommendations.split("\\n").join("\n")}
          </pre>
        </Descriptions.Item>
      )}
       <Descriptions.Item label="Ответственный">
          {serviceOrder.manager}
      </Descriptions.Item>
      <Descriptions.Item label="Ответственный на оплату">
          {serviceOrder.managerForPayment}
      </Descriptions.Item>
      <Descriptions.Item label="Автомобиль сдал">
          {serviceOrder.managerPassed}
      </Descriptions.Item>
      <Descriptions.Item label="Время передачи на оплату">
          {serviceOrder.dateForPayment}
      </Descriptions.Item>
      <Descriptions.Item label="Время передачи клиенту">
          {serviceOrder.datePassed}
      </Descriptions.Item>
      <Descriptions.Item label="ГУИД в 1С ">{serviceOrder.guid1c}</Descriptions.Item>
      <Descriptions.Item label="ГУИД филиала в 1С ">{serviceOrder.branchGuid1c}</Descriptions.Item>
      <Descriptions.Item label="ГУИД клиента в 1С ">{serviceOrder.clientGuid1c}</Descriptions.Item>
      <Descriptions.Item label="ГУИД автомобиля в 1С ">{serviceOrder.carGuid1c}</Descriptions.Item>
      <Descriptions.Item label="Дата выгрузки">
        {displayDateTime(serviceOrder.createdAt)}
      </Descriptions.Item>
    </Descriptions>
  );
}

export default ServiceOrderDescriptions;
