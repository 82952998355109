import React from "react";
import { useAntdTable, useRequest } from "ahooks";
import { Row, Col, PageHeader, Spin, Tag, Popconfirm, message } from "antd";
import { BranchQuestions } from "services/branch-questions/models/branch-questions";
import { BranchQuestionsUpsertDto } from "services/branch-questions/dtos/branch-questions-upsert-dto.type";
import { BranchQuestionsService } from "services/branch-questions/branch-questions.service";
import { branchQuestionsService } from "services";
import BranchQuestionsTable from "./BranchQuestionsTable";
import AddButton from "components/buttons/add-button";
import RefreshButton from "components/buttons/refresh-button";
import BranchQuestionsForm, { BranchQuestionsFormProps } from "./BranchQuestionsForm";
import { ColumnsType } from "antd/es/table";
import RemoveButton from "components/buttons/remove-button";
import { ApiDriverHttpError } from "libs/common/api-driver/api-driver";
import { useAppSelector } from "store/hooks";
import { pageAccessControlSelector } from "store/selectors/page-access-control";
import checkAbilities from "store/helpers/check-abilities";
import { Subject } from "abilities/subject.enum";
import { subjectAction } from "abilities/subject-action.constant";
import { Branch } from "services/branch/models/branch";


const createBranchQuestions: BranchQuestionsService["createOne"] = (currentIssuesId, dto) => {
  const dtoCopy: BranchQuestionsUpsertDto = {...dto, currentIssuesId };
  return branchQuestionsService.createOne(currentIssuesId, dtoCopy);
};

const deleteBranchQuestions: BranchQuestionsService["deleteOne"] = (currentIssuesId, id) =>
    branchQuestionsService.deleteOne(currentIssuesId, id);

export interface BranchQuestionsRelationProps {
  currentIssuesId?: BranchQuestions["currentIssuesId"];
  loading?: boolean;
  buttonsDisabled?: boolean;
  title?: string;
}

const BranchQuestionsRelation: React.FC<BranchQuestionsRelationProps> = (props) => {
  const {
    currentIssuesId,
    loading: loadingProp = false,
    buttonsDisabled = false,
    title = "Филиалы вопроса",
  } = props;

  const pageAccessControlState = useAppSelector(pageAccessControlSelector);

  const accessControl = React.useMemo(() => {
    if (pageAccessControlState.loading) {
      return {
        update: false,
        delete: false,
      };
    }
    return {
      update: checkAbilities(pageAccessControlState.abilities, {
        subject: Subject.CURRENT_ISSUES,
        action: subjectAction.CURRENT_ISSUES.UPDATE_ALL,
      }),
      delete: checkAbilities(pageAccessControlState.abilities, {
        subject: Subject.CURRENT_ISSUES,
        action: subjectAction.CURRENT_ISSUES.DELETE_ALL,
      }),
    };
  }, [pageAccessControlState]);

  const [addBranchQuestions, setAddBranchQuestions] = React.useState<boolean>(false);

  const getTableData = React.useMemo(() => {
    if (!currentIssuesId) {
      return async () => {
        return {
          list: [],
          total: 0,
        };
      };
    }

    return branchQuestionsService.getBranchQuestionsTableDataGetter(currentIssuesId);
  }, [currentIssuesId]);

  const {
    tableProps,
    loading: getTableDataLoading,
    refresh,
    params,
  } = useAntdTable(getTableData, {
    defaultType: "simple",
    cacheKey: `BranchQuestionsTable-${currentIssuesId}`,
    cacheTime: 10 * 1000,
    loadingDelay: 500,
    defaultParams: [
      {
        current: 1,
        pageSize: 10,
        sorter: {},
      },
    ],
    refreshDeps: [currentIssuesId],
  });

  const { sorter = {} } = params[0] || ({} as any);

  const { run: runCreateBranchQuestions, loading: createBranchQuestionsLoading } = useRequest(
    createBranchQuestions,
    {
      manual: true,
      onSuccess: () => {
        setAddBranchQuestions(false);
        refresh();
      },
      onError: (e) => {
        if (e instanceof ApiDriverHttpError) {
          if (e.status === 409) {
            message.error("Такой филиал уже существует!");
          }
        }
      },
    }
  );

  const { run: runDeleteBranchQuestions, loading: deleteBranchQuestionsLoading } = useRequest(
    deleteBranchQuestions,
    {
      manual: true,
      onSuccess: () => {
        setAddBranchQuestions(false);
        refresh();
      },
      onError: () => refresh(),
    }
  );

  const handleBranchQuestionsFormFinish: Required<BranchQuestionsFormProps>["onFinish"] = React.useCallback(
    (dto) => {
      if (currentIssuesId) {
        runCreateBranchQuestions(currentIssuesId, dto);
      }
    },
    [currentIssuesId, runCreateBranchQuestions]
  );

  const handleDelete = React.useCallback(
    async (BranchQuestionsId: BranchQuestions["id"]) => {
      if (!BranchQuestionsId || !currentIssuesId) {
        return;
      }
      await runDeleteBranchQuestions(currentIssuesId, BranchQuestionsId);
    },
    [runDeleteBranchQuestions, currentIssuesId]
  );

  const roleBranchQuestionsTableRightExtra: ColumnsType<BranchQuestions> = React.useMemo(() => {
    if (!accessControl.delete) {
      return [];
    }

    return [
      {
        title: "",
        dataIndex: "_",
        key: "_",
        ellipsis: true,
        align: "center",
        fixed: "right",
        render: (_: any, record) => (
          <Popconfirm
            title="Вы уверены? Это действие будет нельзя отменить."
            okText="Удалить"
            okButtonProps={{ danger: true }}
            onConfirm={() => handleDelete(record.id)}
          >
            <RemoveButton
              disabled={getTableDataLoading || createBranchQuestionsLoading}
              loading={deleteBranchQuestionsLoading}
            />
          </Popconfirm>
        ),
      },
    ];
  }, [
    getTableDataLoading,
    createBranchQuestionsLoading,
    deleteBranchQuestionsLoading,
    handleDelete,
    accessControl,
  ]);

  if (!currentIssuesId) {
    return null;
  }

  return (
    <PageHeader
      title={title}
      subTitle={<Tag color="warning">Вносимые изменения вступят в силу в течение 10 минут</Tag>}
      extra={
        <Row gutter={[8, 8]}>
          {accessControl.update ? (
            <Col>
              <AddButton
                disabled={buttonsDisabled}
                danger={addBranchQuestions}
                onClick={() => setAddBranchQuestions(!addBranchQuestions)}
              >
                {addBranchQuestions ? "Отмена" : "Добавить"}
              </AddButton>
            </Col>
          ) : null}
          <Col>
            <RefreshButton
              disabled={buttonsDisabled}
              loading={getTableDataLoading}
              onClick={refresh}
            />
          </Col>
        </Row>
      }
    >
      <Spin spinning={loadingProp} delay={500}>
        {addBranchQuestions && (
          <div style={{ marginBottom: "24px" }}>
            <Spin spinning={createBranchQuestionsLoading || deleteBranchQuestionsLoading}>
              <BranchQuestionsForm
                title="Добавить филиал"
                initialValues={{ currentIssuesId }}
                disabledFields={{ currentIssuesId: true }}
                onFinish={handleBranchQuestionsFormFinish}
              />
            </Spin>
          </div>
        )}

        <BranchQuestionsTable
          tableProps={tableProps}
          sorter={sorter}
          size="small"
          rightExtra={roleBranchQuestionsTableRightExtra}
        />
      </Spin>
    </PageHeader>
  );
};

export default BranchQuestionsRelation;
