import React from "react";
import { ArrowRightOutlined, EditOutlined, PhoneOutlined, MailOutlined } from "@ant-design/icons";
import { gold } from "@ant-design/colors";
import { TableState } from "libs/interfaces/table-state.interface";
import { Review } from "services/review/review.service";
import { SortOrder, ColumnsType } from "antd/es/table/interface";
import { getColumnSearchProps } from "components/common/table-column-search-filter/table-column-search-filter";
import Highlighter from "react-highlight-words";
import { displayDateTime } from "libs/helpers/display-date-time";
import ReviewsPage from "pages/reviews/reviews.page";
import ReviewCardPage from "../../card/card.page";
import { Link } from "react-router-dom";
import { User } from "services/user/models/user";
import { ReviewQuestions } from "services/review/models/review-questions.model";
import { any } from "prop-types";
import moment from "moment";
import { Branch } from "services/branch/models/branch";

function getColumns(tableState: TableState<ReviewQuestions>, branches: Branch[],): ColumnsType<ReviewQuestions> {
  let typeAnswerSortorder: SortOrder = null;
//   let updatedAtSortOrder: SortOrder = null;


//   if (Array.isArray(tableState)) {
//     //...
//   } else {
//     typeAnswerSortorder =
//       tableState === "typeAnswer" && tableState
//         ? tableState
//         : null;
    
//   }

  return [
    {
      title: "UID Отзыва",
      dataIndex: "reviewId",
      key: "reviewId",
      render: (_: any, record) => {
       
        return record.reviewId
      },
        
     
      ellipsis: true,
    },
    {
      title: "Вопрос",
      dataIndex: "currentIssues",
      key: "currentIssues",
      sorter: (a, b) => {
        if (a.currentIssues?.name < b.currentIssues?.name) return -1;
        if (a.currentIssues?.name > b.currentIssues?.name) return 1;
        return 0;
    },
    onFilter: (value, record) => {
        console.log(value, record);
        return false;
    },
      render: (_: any, record) => record?.currentIssues?.name,
      
      ellipsis: true,
    },
    {
      title: "Ответ",
      dataIndex: "answer",
      key: "answer",
      render: (_: any, record) => record?.answer?.answer,
      ellipsis: true,
    },
    {
      title: "Тип ответа",
      dataIndex: "typeAnswer",
      key: "typeAnswer",
      sorter: (a, b) => {
        if (a.answer?.typeAnswer < b.answer?.typeAnswer) return -1;
        if (a.answer?.typeAnswer > b.answer?.typeAnswer) return 1;
        return 0;
    },
    onFilter: (value, record) => {
        console.log(value, record);
        return false;
    },
      render: (_: any, record) => record?.answer?.typeAnswer,
      
      ellipsis: true,
    },
    {
      title: "Филиал",
      dataIndex: "branch",
      key: "branch",
      render: (_: any, record) => record?.review?.branch?.name,
      sorter: (a, b) => {
          const nameA = a.review?.branch?.name || '';
          const nameB = b.review?.branch?.name || '';
          return nameA.localeCompare(nameB);
      },
      ellipsis: true,
      },
    
  
    {
      title: "Дата создания отзыва",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_: any, record) => {
          return moment(record?.review?.createdAt).format('DD.MM.YYYY');
      },
      sorter: (a, b) => {
          const dateA = moment(a.review?.createdAt).valueOf();
          const dateB = moment(b.review?.createdAt).valueOf();
          return dateA - dateB;
      },
      ellipsis: true,
      },
      {
        title: "Дата обновления отзыва",
        dataIndex: "updatedAt",
        key: "updatedAt",
        render: (_: any, record) => {
            return moment(record?.review?.updatedAt).format('DD.MM.YYYY');
        },
        sorter: (a, b) => {
            const dateA = moment(a.review?.updatedAt).valueOf();
            const dateB = moment(b.review?.updatedAt).valueOf();
            return dateA - dateB;
        },
        ellipsis: true,
        },
    {
      title: "",
      key: "action1",
      render: (text: any, record) => (
        <Link
          to={{
            pathname: `${ReviewsPage.path}${ReviewCardPage.pathCreator(record.reviewId)}`,
            state: {
              canGoBack: true,
            },
          }}
        >
          <ArrowRightOutlined />
        </Link>
      ),
      fixed: "right",
      align: "center",
    },
   
  ];
}

export default getColumns;
