import React from "react";
import { Form, Input } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { noOp } from "libs/helpers/no-op";

export interface CurrentIssuesTableSimpleFormDto {
  name?: string;
}

export interface CurrentIssuesTableSimpleFormProps {
  form?: FormInstance<CurrentIssuesTableSimpleFormDto>;
  submit?: () => void;
  reset?: () => void;
}

const CurrentIssuesTableSimpleForm: React.FC<CurrentIssuesTableSimpleFormProps> = (props) => {
  const { form: formProp, submit = noOp /*, reset = noOp */ } = props;

  const [form] = Form.useForm(formProp);

  return (
    <Form layout="inline" form={form}>
      <Form.Item name="name" label="Название">
        <Input.Search onSearch={submit} allowClear />
      </Form.Item>
    </Form>
  );
};

export default CurrentIssuesTableSimpleForm;
