import { Subject } from "./subject.enum";

enum BlacklistClientAction {
  CREATE = "CREATE",
  DELETE_ALL = "DELETE_ALL",
  DELETE_OWN = "DELETE_OWN",
}

enum ReviewAction {
  READ_ALL = "READ_ALL",
  READ_OWN = "READ_OWN",
  CREATE = "CREATE",
  UPDATE_ALL = "UPDATE_ALL",
  UPDATE_OWN = "UPDATE_OWN",
  DELETE_ALL = "DELETE_ALL",
  DELETE_OWN = "DELETE_OWN",
  SET_READY_TO_CLOSE_STATUS = "SET_READY_TO_CLOSE_STATUS",
  SET_CLOSED_STATUS = "SET_CLOSED_STATUS",
}

enum ServiceOrderAction {
  READ_ALL = "READ_ALL",
}

enum BranchAction {
  CREATE = "CREATE",
  UPDATE_ALL = "UPDATE_ALL",
  DELETE_ALL = "DELETE_ALL",
}

enum CurrentIssuesAction {
  CREATE = "CREATE",
  UPDATE_ALL = "UPDATE_ALL",
  DELETE_ALL = "DELETE_ALL",
  READ_ALL = "READ_ALL",
}

enum UserAction {
  READ_ALL = "READ_ALL",
  CREATE = "CREATE",
  UPDATE_ALL = "UPDATE_ALL",
  DELETE_ALL = "DELETE_ALL",
}

enum ReviewSiteAction {
  CREATE = "CREATE",
  UPDATE_ALL = "UPDATE_ALL",
  DELETE_ALL = "DELETE_ALL",
}

enum ReviewTagTypeAction {
  CREATE = "CREATE",
  UPDATE_ALL = "UPDATE_ALL",
  DELETE_ALL = "DELETE_ALL",
}

enum IntegrationKeyAction {
  READ_ALL = "READ_ALL",
  CREATE = "CREATE",
  UPDATE_ALL = "UPDATE_ALL",
  DELETE_ALL = "DELETE_ALL",
}

enum BackendCacheAction {
  CLEAR = "CLEAR",
}

enum CityAction {
  CREATE = "CREATE",
  UPDATE_ALL = "UPDATE_ALL",
  DELETE_ALL = "DELETE_ALL",
}

enum RoleAction {
  CREATE = "CREATE",
  UPDATE_ALL = "UPDATE_ALL",
  DELETE_ALL = "DELETE_ALL",
}

enum ReviewDestinationTypeAction {
  CREATE = "CREATE",
  UPDATE_ALL = "UPDATE_ALL",
  DELETE_ALL = "DELETE_ALL",
}

enum StatisticsAction {
  READ_ALL = "READ_ALL",
}

enum ReminderAction {
  READ_ALL = "READ_ALL",
  READ_OWN = "READ_OWN",
  CREATE = "CREATE",
  UPDATE_ALL = "UPDATE_ALL",
  UPDATE_OWN = "UPDATE_OWN",
  DELETE_ALL = "DELETE_ALL",
  DELETE_OWN = "DELETE_OWN",
}


export const subjectAction = {
  [Subject.BLACKLIST_CLIENT]: BlacklistClientAction,
  [Subject.REVIEW]: ReviewAction,
  [Subject.SERVICE_ORDER]: ServiceOrderAction,
  [Subject.BRANCH]: BranchAction,
  [Subject.USER]: UserAction,
  [Subject.REVIEW_SITE]: ReviewSiteAction,
  [Subject.REVIEW_TAG_TYPE]: ReviewTagTypeAction,
  [Subject.INTEGRATION_KEY]: IntegrationKeyAction,
  [Subject.BACKEND_CACHE]: BackendCacheAction,
  [Subject.CITY]: CityAction,
  [Subject.ROLE]: RoleAction,
  [Subject.REVIEW_DESTINATION_TYPE]: ReviewDestinationTypeAction,
  [Subject.STATISTICS]: StatisticsAction,
  [Subject.REMINDER]: ReminderAction,
  [Subject.CURRENT_ISSUES]:CurrentIssuesAction,
};
