import { plainToClass } from "class-transformer";
import { RequestQueryBuilder, CreateQueryParams } from "@nestjsx/crud-request";
import ApiDriver from "libs/common/api-driver/api-driver";
import { PagiantedResponse } from "../interfaces/paginated-response.interface";
import { Review } from "./models/review.model";
import { PartialDeep } from "type-fest";
import {
  ReviewServiceOrderPartialById,
  ReviewServiceOrder,
} from "services/models/review-service-order.model";
import { ReviewWebLinkPartialById, ReviewWebLink } from "./models/review-web-link.model";
import { ReviewClientCallPartialById, ReviewClientCall } from "./models/review-client-call.model";
import { ReviewClientEmailPartialById, ReviewClientEmail } from "./models/review-client-email";
import { ReviewChatMessage } from "./models/review-chat-message.model";
import { ReviewDestinationType } from "services/review-destination-type/models/review-destination-type";
import { Branch } from "services/branch/models/branch";
import { ReviewQuestions } from "./models/review-questions.model";

export { Review };

export type ReviewAttachment =
  | ReviewServiceOrderPartialById
  | ReviewWebLinkPartialById
  | ReviewClientCallPartialById
  | ReviewClientEmailPartialById;
  

export class ReviewService {
  constructor(private readonly apiDriver: ApiDriver) {}

  async createOneWithServiceOrder(serviceOrderId: string, useLimits?: boolean) {
    const createdReview = await this.apiDriver.post<Review>("/review/with-service-order", {
      serviceOrderId,
      useLimits,
    });
    const { id } = createdReview;
    return id;
  }

  async createOne(dto: PartialDeep<Review>) {
    return this.apiDriver.post<Review>("/review", dto);
  }

  async findAll(params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    let reviews = await this.apiDriver.get<PagiantedResponse<Review> | Review[]>("/review", {
      params: queryParams,
    });

    if (Array.isArray(reviews)) {
      reviews = plainToClass(Review, reviews);
    } else {
      reviews.data = plainToClass(Review, reviews.data);
    }

    return reviews;
  }

  async findOne(id: string, params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    const review = await this.apiDriver.get<Review>(`/review/${id}`, {
      params: queryParams,
    });

    return plainToClass(Review, review);
  }

  async updateOne(id: string, reviewFields: PartialDeep<Review>) {
    const updatedReview = await this.apiDriver.patch<Review>(`/review/${id}`, reviewFields);
    return updatedReview;
  }

  async deleteOne(id: string, params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    await this.apiDriver.delete<Review>(`/review/${id}`, {
      params: queryParams,
    });
  }

  async findAllReviewChatMessages(reviewId: string, params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    let reviewChatMessages = await this.apiDriver.get<
      PagiantedResponse<ReviewChatMessage> | ReviewChatMessage[]
    >(`/review/${reviewId}/review-chat-message/`, {
      params: queryParams,
    });

    if (Array.isArray(reviewChatMessages)) {
      reviewChatMessages = plainToClass(ReviewChatMessage, reviewChatMessages);
    } else {
      reviewChatMessages.data = plainToClass(ReviewChatMessage, reviewChatMessages.data);
    }

    return reviewChatMessages;
  }

  async createOneReviewChatMessage(reviewId: string, message: string) {
    const created = await this.apiDriver.post<ReviewChatMessage>(
      `/review/${reviewId}/review-chat-message/`,
      { reviewId, message }
    );
    return plainToClass(ReviewChatMessage, created);
  }

  async updateOneReviewChatMessage(reviewId: string, reviewChatMessageId: string, message: string) {
    const updated = await this.apiDriver.patch<ReviewChatMessage>(
      `/review/${reviewId}/review-chat-message/${reviewChatMessageId}`,
      { reviewId, message }
    );
    return plainToClass(ReviewChatMessage, updated);
  }

  async deleteOneReviewChatMessage(reviewId: string, reviewChatMessageId: string) {
    return this.apiDriver.delete(`/review/${reviewId}/review-chat-message/${reviewChatMessageId}`);
  }

  async createManyReviewClientCalls(reviewId: string, dto: PartialDeep<ReviewClientCall>[]) {
    return this.apiDriver.post(`/review/${reviewId}/review-client-call/bulk`, {
      bulk: dto,
    });
  }

  async deleteOneReviewClientCall(reviewId: string, reviewClientCallId: string) {
    return this.apiDriver.delete(`/review/${reviewId}/review-client-call/${reviewClientCallId}`);
  }

  async createManyReviewClientEmails(reviewId: string, dto: PartialDeep<ReviewClientEmail>[]) {
    const created = await this.apiDriver.post<ReviewClientEmail[]>(
      `/review/${reviewId}/review-client-email/bulk`,
      {
        bulk: dto,
      }
    );
    return plainToClass(ReviewClientEmail, created);
  }

  async deleteOneReviewClientEmail(reviewId: string, reviewClientEmailId: string) {
    return this.apiDriver.delete(`/review/${reviewId}/review-client-email/${reviewClientEmailId}`);
  }
////
// async createManyReviewQuestions(reviewId: string, dto: PartialDeep<ReviewQuestions>[]) {
//   const created = await this.apiDriver.post<ReviewQuestions[]>(
//     `/review/${reviewId}/review-client-email/bulk`,
//     {
//       bulk: dto,
//     }
//   );
//   return plainToClass(ReviewClientEmail, created);
// }

// async deleteOneReviewQuestions(currentQuestions:[]) {
//   return this.apiDriver.delete(`/review/${reviewId}/review-client-email/${reviewClientEmailId}`);
// }
async setReviewQuestions(reviewId: string, reviewQuestions: ReviewQuestions[]) {
  return this.apiDriver.post(`/review/${reviewId}/review-questions`, { reviewQuestions });
}

async setReviewQuestionsUpdate(reviewId: string, reviewQuestions: ReviewQuestions[]) {
  return this.apiDriver.post(`/review/${reviewId}/review-questions/update`, { reviewQuestions });
}

async getReviewQuestions(){
  return this.apiDriver.get(`/reviewsQuestions/getAll`).then((data)=>data)
}


////
  async createManyReviewServiceOrders(reviewId: string, dto: PartialDeep<ReviewServiceOrder>[]) {
    const created = await this.apiDriver.post<ReviewServiceOrder[]>(
      `/review/${reviewId}/review-service-order/bulk`,
      {
        bulk: dto,
      }
    );
    return plainToClass(ReviewServiceOrder, created);
  }

  async deleteOneReviewServiceOrder(reviewId: string, reviewServiceOrderId: string) {
    return this.apiDriver.delete(
      `/review/${reviewId}/review-service-order/${reviewServiceOrderId}`
    );
  }

  async createManyReviewWebLinks(reviewId: string, dto: PartialDeep<ReviewWebLink>[]) {
    const created = await this.apiDriver.post<ReviewWebLink[]>(
      `/review/${reviewId}/review-web-link/bulk`,
      {
        bulk: dto,
      }
    );
    return plainToClass(ReviewWebLink, created);
  }

  async deleteOneReviewWebLink(reviewId: string, reviewWebLinkId: string) {
    return this.apiDriver.delete(`/review/${reviewId}/review-web-link/${reviewWebLinkId}`);
  }

  async getMyBranchFilter() {
    const data = await this.apiDriver.get<Branch[]>("/branch/review/my-branches");
    return plainToClass(Branch, data);
  }

  async getMyReviewDestinationTypeFilter() {
    const data = await this.apiDriver.get<ReviewDestinationType[]>(
      "/review-destination-type/review/my-review-destination-types"
    );
    return plainToClass(ReviewDestinationType, data);
  }

  async setReadyToCloseStatus(id: string) {
    await this.apiDriver.post(`/review/${id}/ready-to-close`);
  }

  async setClosedStatus(id: string) {
    await this.apiDriver.post(`/review/${id}/close`);
  }

  async setReviewTags(reviewId: string, reviewTagTypeIds: string[]) {
    return this.apiDriver.post(`/review/${reviewId}/tags`, { reviewTagTypeIds });
  }

  async setReviewDestinations(reviewId: string, reviewDestinationTypeIds: string[]) {
    return this.apiDriver.post(`/review/${reviewId}/destinations`, {
      reviewDestinationTypeIds,
    });
  }
}
