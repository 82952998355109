import { CondOperator, QueryFilter, QueryFilterArr } from "@nestjsx/crud-request";
import { Data, Params, Service } from "ahooks/lib/useAntdTable/types";
import { CurrentIssuesTableSimpleFormDto } from "components/tables/CurrentIssuesTable/CurrentIssuesTableSimpleForm";
import { convertTableSorterToQuerySorter } from "libs/helpers/convert-table-sorter-to-query-sorter";
import { currentIssuesService } from "services";
import { CurrentIssues } from "services/current-issues/models/current-issues";

export type GetCurrentIssuesTableData = Service<Data, Params>;

export const getCurrentIssuesTableData: GetCurrentIssuesTableData = async (
  paginatedParams,
  formData: CurrentIssuesTableSimpleFormDto
) => {
  const tableQuerySorter = convertTableSorterToQuerySorter<CurrentIssues>(paginatedParams.sorter);

  const filter: QueryFilter | QueryFilterArr | (QueryFilter | QueryFilterArr)[] = [];

  if (formData.name) {
    filter.push({
      field: "name",
      operator: CondOperator.CONTAINS_LOW,
      value: formData.name.trim(),
    });
  }



  const issues = await currentIssuesService.findAll({
    page: paginatedParams.current,
    limit: paginatedParams.pageSize,
    sort: tableQuerySorter
      ? [tableQuerySorter, { field: "id", order: "ASC" }]
      : { field: "id", order: "ASC" },
    
  });

  if (Array.isArray(issues)) {
    return {
      list: issues,
      total: issues.length,
    };
  } else {
    return {
      list: issues.data,
      total: issues.total,
    };
  }
};
