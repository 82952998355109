import React, { useState } from "react";
import { Form, Descriptions, Button, Space, message } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { BranchQuestionsUpsertDto } from "services/branch-questions/dtos/branch-questions-upsert-dto.type";
import CurrentIssuesSelect from "components/selects/CurrentIssuesSelect";
import BranchSelect from "components/selects/BranchSelect";
import { BranchQuestionsService } from "services/branch-questions/branch-questions.service";
import { BranchService } from "services/branch/branch.service";
import { Branch } from "services/branch/models/branch";
import { apiDriver } from "api-driver.instance";
import { BranchQuestions } from "services/branch-questions/models/branch-questions";
import { Store } from "antd/es/form/interface";
import { noOp } from "libs/helpers/no-op";
export type BranchQuestionsFormDto = BranchQuestionsUpsertDto;

export interface BranchQuestionsFormProps {
form?: FormInstance<BranchQuestionsFormDto>;
onFinish?: (dto: BranchQuestionsUpsertDto, entityToUpdate?: BranchQuestions) => void;
entityToUpdate?: BranchQuestions;
initialValues?: Partial<BranchQuestionsFormDto>;
disabledFields?: { [key in keyof BranchQuestionsFormDto]?: boolean };
title?: string;
}

const COLUMN = { xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 };

const BranchQuestionsForm: React.FC<BranchQuestionsFormProps> = (props) => {
const {
    form: formProp,
    onFinish = () => {},
    entityToUpdate,
    initialValues: initialValuesProp,
    title = "Филиалы",
    disabledFields: disabledFieldsProp,
} = props;

const [form] = Form.useForm(formProp);




const initialValues: Store | undefined = React.useMemo(() => {
    const iV: Store = {
      isHead: false,
    };

    if (entityToUpdate) {
      Object.assign(iV, entityToUpdate);
    }

    if (initialValuesProp) {
      Object.assign(iV, initialValuesProp);
    }

    return iV;
  }, [entityToUpdate, initialValuesProp]);

  const reset = React.useCallback(() => {
    form.resetFields();
   
  }, [form, initialValues]);


  const handleFinish = React.useCallback(
    (dto: BranchQuestionsFormDto) => {
      onFinish(dto, entityToUpdate);
    },
    [onFinish, entityToUpdate]
  );

const [loading, setLoading]=useState(false)


const handleAllBranchesClick = async () => {
 
    try {
      setLoading(true)
     const branchService = new BranchService(apiDriver);
     const branchQuestionsService = new BranchQuestionsService(apiDriver);

     const response: Branch[] | { data: Branch[] } = await branchService.findAll();
     const allBranches = Array.isArray(response) ? response : response.data;

     const branchQuestionsData = allBranches.map((branch) => ({
        ...initialValuesProp, // Используем начальные значения формы
        branchId: branch.id,
     }));
        console.log("ДАННЫЕ", branchQuestionsData)
     for(const br of branchQuestionsData){
        const branchQuestionDto: BranchQuestionsUpsertDto = {
            currentIssuesId: br.currentIssuesId ?? "defaultId", // Убедитесь, что это значение корректно
            branchId: br.branchId,
            // Добавьте другие необходимые поля, если они есть
         };
    
    


        const currentIssuesId= br.currentIssuesId ?? "defaultId";
        if (currentIssuesId) {
            await branchQuestionsService.createOne(currentIssuesId, branchQuestionDto);
         } else {
            console.warn("currentIssuesId is undefined for branch:", br.branchId);
         }
     //await branchQuestionsService.createOne(br.currentIssuesId??"default",br.branchId);
     }
     message.success("Все филиалы успешно добавлены.");
      setLoading(false)
    } catch (error) {
     console.error("Ошибка при добавлении филиалов:", error);
     message.error("Ошибка при добавлении филиалов.");
     setLoading(false)
    }
};

return (
    <Form form={form} initialValues={initialValues} onFinish={handleFinish}>
     <Descriptions title={title} column={COLUMN}>
        <Descriptions.Item label={<strong>Id</strong>}>{entityToUpdate?.id}</Descriptions.Item>
        <Descriptions.Item>
         <Form.Item
            id="currentIssuesId"
            name="currentIssuesId"
            label={<strong>Вопрос</strong>}
            style={{ marginBottom: 0, width: "100%" }}
            rules={[{ required: true, message: "Это поле должно быть заполнено." }]}
         >
            <CurrentIssuesSelect disabled={disabledFieldsProp?.currentIssuesId} />
         </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item>
         <Form.Item
            id="branchId"
            name="branchId"
            label={<strong>Филиал</strong>}
            style={{ marginBottom: 0, width: "100%" }}
            rules={[{ required: true, message: "Это поле должно быть заполнено." }]}
         >
            <BranchSelect disabled={disabledFieldsProp?.branchId} />
         </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item>
         <Form.Item>
          Если вы хотите добавить вопрос для ВСЕХ филиалов, то используйте кнопку "Для всех филиалов". При этом не нужно выбирать филиалы. После нажатия на данную кнопку,
           нажмите кнопку "Обновить" на форме "Филиалы вопроса". ВАЖНО! Не нажимайте кнопку "Для всех филиалов" несколько раз!
         </Form.Item>
        </Descriptions.Item>
     </Descriptions>
     <Form.Item noStyle>
        <Space style={{ display: "flex", justifyContent: "flex-end" }}>
         <Button type="primary" size="large" htmlType="submit">
            Сохранить
         </Button>
         <Button type="text" size="large" onClick={() => form.resetFields()}>
            Сбросить
         </Button>
         <Button type="primary" disabled={loading} size="large" onClick={handleAllBranchesClick}>
            Для всех филиалов
         </Button>
        </Space>
     </Form.Item>
    </Form>
);
};

export default BranchQuestionsForm;