import { Type } from "class-transformer";

export class CurrentIssues {
    id!: string;
    name!: string;

  
  
    createByUserId:string;
  
    @Type(() => Date)
    createDate!: Date;
  
    @Type(() => Date)
    deleteDate!: Date;
  
   
  }