import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { PageHeader, Row, Col, Spin, Popconfirm, message, Tabs } from "antd";
import { useMount, useRequest } from "ahooks";
import ContentLayout from "components/layouts/content.layout";
import Breadcrumbs from "components/common/breadcrumbs";
import BranchDescription from "components/descriptions/BranchDescription";
import { currentIssuesService } from "services";
import { CurrentIssues } from "services/current-issues/models/current-issues";
import CurrentIssuesForm, {CurrentIssuesFormDto} from "components/forms/CurrentIssuesForm";
import { CurrentIssuesService } from "services/current-issues/current-issues.service";
import { useSetTitleEffect } from "store/hooks";
import SettingsPage from "pages/settings/settings.page";
import { PageFCWithPathCreator } from "libs/interfaces/page-fc-with-path-creator";
import useGoBack from "hooks/use-go-back";
import HttpErrorResult from "components/common/http-error-result";
import { ApiDriverHttpError } from "libs/common/api-driver/api-driver";
import EditButton from "components/buttons/edit-button";
import RemoveButton from "components/buttons/remove-button";
import RefreshButton from "components/buttons/refresh-button";
import CurrentsIssuesSettingsPage from "../currents-issues/currents-issues.page";
import { Subject } from "abilities/subject.enum";
import { subjectAction } from "abilities/subject-action.constant";
import CheckAbilities from "store/components/CheckAbilities";
import CurrentIssuesDescription from "components/descriptions/CurrentIssuesDescription";
import AnswersRelation from "./components/AnswersRelation";
import BranchQuestionsRelation from "./components/BranchQuestionsRelation";

export interface CurrentIssuesSettingsPageParams {
  id: CurrentIssues["id"];
}
//const aaaa:any=CurrentIssuesService
 const fetchCurrentIssues = (id: CurrentIssues["id"]) => currentIssuesService.findOne(id);

 const updateCurrentIssues: CurrentIssuesService["updateOne"] = (id, dto) => currentIssuesService.updateOne(id, dto);

 const deleteCurrentIssues = (id: CurrentIssues["id"]) => currentIssuesService.deleteOne(id);
 const { TabPane } = Tabs;
 const CurrentIssuesSettingsPage: PageFCWithPathCreator = () => {
   const { id } = useParams<CurrentIssuesSettingsPageParams>();

  const goBack = useGoBack();

  const history = useHistory();

  const {
    run: runFetchCurrentIssues,
    data: currentIssues,
    error: fetchCurrentIssuesError,
    loading: fetchCurrentIssuesLoading,
    refresh: refreshCurrentIssues,
    mutate: mutateCurrentIssues,
  } = useRequest(fetchCurrentIssues, {
    manual: true,
  });

   const { runAsync: runUpdateCurrentIssues, loading: updateCurrentIssuesLoading } = useRequest(updateCurrentIssues, {
     manual: true,
     onError: (e, params) => {
       const [, dto] = params;
       const { name } = dto;
       if (e instanceof ApiDriverHttpError) {
         if (e.status === 409) {
           message.error(`Данный вопрос "${name}" уже существует!`);
         }
       }
     },
   });
   const { run: runDeleteCurrentIssues, loading: deleteCurrentIssuesLoading } = useRequest(deleteCurrentIssues, {
     manual: true,
     onSuccess: () => {
       history.push(`${SettingsPage.path}${CurrentsIssuesSettingsPage.path}`);
     },
   });

   const [edit, setEdit] = React.useState<boolean>(false);

   useSetTitleEffect([SettingsPage.label, CurrentIssuesSettingsPage.label, currentIssues?.name || id]);
   
   useMount(() => {
    if (id) {
      console.log("РАБОТАЕТ");
      runFetchCurrentIssues(id);
      console.log(runFetchCurrentIssues(id));
    }
  })
  
    const handleUpdate = React.useCallback(
      async (dto: CurrentIssuesFormDto) => {
      if (!currentIssues) {
        return;
      }
      const updated = await runUpdateCurrentIssues(currentIssues.id, dto);
      mutateCurrentIssues(updated);
      setEdit(false);
    }, [currentIssues, runUpdateCurrentIssues, mutateCurrentIssues, setEdit]);

    const handleDelete = React.useCallback(async () => {
    if (!currentIssues) {
      return;
    }
    await runDeleteCurrentIssues(currentIssues.id);
    setEdit(false);
    await refreshCurrentIssues();
  }, [currentIssues, runDeleteCurrentIssues, setEdit, refreshCurrentIssues]);

  return (
    <ContentLayout>
      <Breadcrumbs items={[SettingsPage.label, CurrentIssuesSettingsPage.label, currentIssues?.name || id]} />
      <PageHeader
        title={CurrentIssuesSettingsPage.label}
        subTitle={currentIssues?.name}
        extra={
          <Row gutter={[8, 8]}>
            <CheckAbilities
              abilities={[
                {
                  subject: Subject.CURRENT_ISSUES,
                  action: subjectAction.CURRENT_ISSUES.UPDATE_ALL,
                },
              ]}
              placeholder={null}
            >
              <Col>
                <EditButton
                  disabled={fetchCurrentIssuesLoading || deleteCurrentIssuesLoading || !currentIssues}
                  loading={updateCurrentIssuesLoading}
                  danger={edit}
                  onClick={() => setEdit(!edit)}
                >
                  {edit ? "Отмена" : "Изменить"}
                </EditButton>
              </Col>
            </CheckAbilities>
            <CheckAbilities
              abilities={[
                {
                  subject: Subject.CURRENT_ISSUES,
                  action: subjectAction.CURRENT_ISSUES.DELETE_ALL,
                },
              ]}
              placeholder={null}
            >
              <Col>
                <Popconfirm
                  title="Вы уверены? Это действие будет нельзя отменить."
                  okText="Удалить"
                  okButtonProps={{ danger: true }}
                  onConfirm={handleDelete}
                >
                  <RemoveButton
                    disabled={fetchCurrentIssuesLoading || updateCurrentIssuesLoading || !currentIssues}
                    loading={deleteCurrentIssuesLoading}
                  >
                    Удалить
                  </RemoveButton>
                </Popconfirm>
              </Col>
            </CheckAbilities>
            <Col>
              <RefreshButton
                disabled={updateCurrentIssuesLoading || deleteCurrentIssuesLoading}
                loading={fetchCurrentIssuesLoading}
                onClick={refreshCurrentIssues}
              />
            </Col>
          </Row>
        }
        onBack={goBack}
      >
        <Spin spinning={fetchCurrentIssuesLoading} delay={500}>
          <HttpErrorResult error={fetchCurrentIssuesError} />
          {edit ? (
            <Spin spinning={updateCurrentIssuesLoading}>
              <CurrentIssuesForm entityToUpdate={currentIssues} onFinish={handleUpdate} title={""} />
            </Spin>
          ) : (
            currentIssues && <CurrentIssuesDescription data={currentIssues} title={""} />
          )}
        </Spin>
      </PageHeader>
      {currentIssues && (
        <Tabs type="card" style={{ marginTop: "16px" }} tabBarStyle={{ marginBottom: "0" }}>

          <TabPane tab="Ответы" key="1">
            <AnswersRelation currentIssuesId={currentIssues.id} />
          </TabPane>
          <TabPane tab="Филиалы" key="2">
            <BranchQuestionsRelation currentIssuesId={currentIssues.id} />
          </TabPane>
          
        </Tabs>
      )}





    </ContentLayout>
  );
};

CurrentIssuesSettingsPage.path = "/current-issues/:id";
CurrentIssuesSettingsPage.pathCreator = (id) => `/current-issues/${id}`;
CurrentIssuesSettingsPage.label = "Вопрос";

export default CurrentIssuesSettingsPage;
