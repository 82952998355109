import { Type } from "class-transformer";
import { CurrentIssues } from "services/current-issues/models/current-issues";

export class Answers {
  id!: string;
  currentIssuesId!: string;
  answer!: string;
  typeAnswer!:string;

  @Type(() => Date)
  createdAt!: Date;

  @Type(() => CurrentIssues)
  currentIssues?: CurrentIssues;
}
