import React, { useEffect } from "react";
import { Rate, Form, Input, Button, Checkbox, Select, Space, Popconfirm, Typography } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { Store } from "antd/lib/form/interface";
import { Review, ReviewAttachment } from "services/review/review.service";
import { ReviewDestinationType } from "services/review-destination-type/models/review-destination-type";
import {
  ReviewServiceOrder,
  ReviewServiceOrderPartialById,
} from "services/models/review-service-order.model";
import {
  ReviewWebLink,
  ReviewWebLinkPartialById,
} from "services/review/models/review-web-link.model";
import {
  ReviewClientCall,
  ReviewClientCallPartialById,
} from "services/review/models/review-client-call.model";
import {
  ReviewClientEmail,
  ReviewClientEmailPartialById,
} from "services/review/models/review-client-email";
import { noOp } from "libs/helpers/no-op";
import { Branch } from "services/branch/models/branch";
import { ReviewTagType } from "services/review-tag-type/models/review-tag-type";
import AddButton from "components/buttons/add-button";
import ReviewAttachmentCollapse, {
  ReviewAttachmentsCollapseExtraPropProps,
} from "components/common/review-attachments-collapse";
import useState from "hooks/use-state";
import AddReviewAttachmentDrawer from "./add-review-attachment-drawer/add-review-attachment-drawer";
import AntdFormReactQuillInput from "components/common/antd-form-react-quill-input";
import { ReviewDestination } from "services/review/models/review-destination.model";
import { ReviewTag } from "services/review/models/review-tag.model";
import ReviewStatus from "components/common/review-status";
import { ReviewStatusEnum } from "libs/enums/review-status.enum";
import { CurrentIssues } from "services/current-issues/models/current-issues";
import { CurrentIssuesService } from "services/current-issues/current-issues.service";
import { apiDriver } from "api-driver.instance";
import { Answers } from "services/answers/models/answers";
import { answersService, branchQuestionsService } from "services";
import { BranchQuestions } from "services/branch-questions/models/branch-questions";
import { ReviewQuestions } from "services/review/models/review-questions.model";

const { Paragraph } = Typography;

const CheckboxGroup = Checkbox.Group;
const { Option } = Select;

// const [selectedBranch, setSelectedBranch] = useState<Branch[]>([]); // Состояние для выбранного филиала
export interface ReviewFormProps {
  review?: Review;
  reviewDestinationTypes?: ReviewDestinationType[];
  branches?: Branch[];
  currentsIssues?: CurrentIssues[];
  reviewTagTypes?: ReviewTagType[];
  contentIsRequired?: boolean;

  // answers:Answers[];
  onSubmit?: (newReview: Review) => void;
}

export interface ReviewFormAttachmentsState {
  reviewServiceOrders: ReviewServiceOrderPartialById[];
  reviewWebLinks: ReviewWebLinkPartialById[];
  reviewClientCalls: ReviewClientCallPartialById[];
  reviewClientEmails: ReviewClientEmailPartialById[];
  reviewQuestions:ReviewQuestions[];
}

export interface ReviewFormInstance {
  resetForm: () => void;
}

function removeReviewAttachment(
  attachment: ReviewAttachment,
  setAttachments: React.Dispatch<React.SetStateAction<ReviewFormAttachmentsState>>
) {
  if (attachment instanceof ReviewServiceOrder) {
    setAttachments((s) => ({
      ...s,
      reviewServiceOrders: s.reviewServiceOrders?.filter((a) => a !== attachment),
    }));
  } else if (attachment instanceof ReviewWebLink) {
    setAttachments((s) => ({
      ...s,
      reviewWebLinks: s.reviewWebLinks?.filter((a) => a !== attachment),
    }));
  } else if (attachment instanceof ReviewClientCall) {
    setAttachments((s) => ({
      ...s,
      reviewClientCalls: s.reviewClientCalls?.filter((a) => a !== attachment),
    }));
  } else if (attachment instanceof ReviewClientEmail) {
    setAttachments((s) => ({
      ...s,
      reviewClientEmails: s.reviewClientEmails?.filter((a) => a !== attachment),
    }));
  }
}

function getReviewFormInitialValues(review: Review | undefined) {
  if (!review) {
    return {};
  }

  const reviewDestinationTypeIds = review.reviewDestinations?.map(
    (reviewDestination) => reviewDestination.reviewDestinationTypeId
  );
  console.log(review)
  return {
    id: review.id,
    branchId: review.branchId,
    rate: review.rate,
    content: review.content,
    comment: review.comment,
    status: review.status,
    clientDisplayName: review.clientDisplayName,
    clientPhone: review.clientPhone,
    clientEmail: review.clientEmail,
    reviewDestinationTypeIds,
    reviewTagTypeIds: review.reviewTags?.map((reviewTag) => reviewTag.reviewTagTypeId),
    reviewQuestions:review.reviewQuestions

  };
}

function getInitialReviewAttachments(review?: Review): ReviewFormAttachmentsState {
  if (!review) {
    return {
      reviewServiceOrders: [],
      reviewWebLinks: [],
      reviewClientCalls: [],
      reviewClientEmails: [],
      reviewQuestions:[],
    };
  }

  return {
    reviewServiceOrders: review.reviewServiceOrders || [],
    reviewWebLinks: review.reviewWebLinks || [],
    reviewClientCalls: review.reviewClientCalls || [],
    reviewClientEmails: review.reviewClientEmails || [],
    reviewQuestions:review.reviewQuestions ||[],
  };
}

const ReviewForm = React.forwardRef<ReviewFormInstance, ReviewFormProps>((props, ref) => {
  const {
    review,
    reviewDestinationTypes = [],
    branches = [],
    reviewTagTypes = [],
    contentIsRequired = false,
    onSubmit = noOp,
  } = props;
  const [attachments, setAttachments] = useState<ReviewFormAttachmentsState>(
    getInitialReviewAttachments(review)
  );
  const [drawerVisible, setDrawerVisible] = React.useState<boolean>(false);
  const [phoneToCopy, setPhoneToCopy] = React.useState("");
  const [form] = Form.useForm();

  const formInitialValues = React.useMemo(() => getReviewFormInitialValues(review), [review]);



  const currentIssuesService = new CurrentIssuesService(apiDriver);
  //let issues =  currentIssuesService.findAll();

  // console.log("НАЗВАНИЯ ВОПРОСОВ",currentsIssues.find())



  const [validIssues, setValidIssues] = useState<CurrentIssues[]>([]);
  const [answers, setAnswer] = useState<Answers[]>([]);
  const [branchQuestions, setBranchQuestions] = useState<BranchQuestions[]>([]);
  const [branchID, setBranchID] = useState<string | undefined>(undefined);
  const [currentQuestions, setCurrentQuestions] = useState<any[]>([])
  useEffect(() => {
    if (!branchID) {
      return;
    }
    fetchCurrentIssues(branchID)
    console.log(validIssues)


  }, [branchID]);

  const fetchCurrentIssues = async (branchID: string | undefined) => {
    try {
      const response = await currentIssuesService.findAll();
      const allBranches = Array.isArray(response) ? response : response.data;

      const currentIssuesData = allBranches.map((currentIssue) => ({
        id: currentIssue.id,
        name: currentIssue.name,
        createDate: new Date(currentIssue.createDate),
        deleteDate: new Date(currentIssue.deleteDate),
        createByUserId: currentIssue.createByUserId,
        // добавьте другие необходимые свойства здесь
      }));

      const currentDate = new Date();
      const filteredIssues = currentIssuesData.filter(issue =>
        currentDate >= issue.createDate && currentDate < issue.deleteDate
      );
      const allAnswers = [];
      for (const issue of filteredIssues) {
        const response2 = await answersService.findAll(issue.id); // Передача currentIssuesId
        const issueAnswers = Array.isArray(response2) ? response2 : response2.data;
        allAnswers.push(...issueAnswers);
      }

      const allBranchQuestions = [];
      for (const issue of filteredIssues) {
        const response2 = await branchQuestionsService.findAll(issue.id) // Передача currentIssuesId
        const issueBranchQuestions = Array.isArray(response2) ? response2 : response2.data;

        allBranchQuestions.push(...issueBranchQuestions.filter(val => val.branchId === branchID));
      }


      console.log("Отфильтровано",filteredIssues)

      // Установка данных в состояние
      setValidIssues(filteredIssues);
      setAnswer(allAnswers);
      setBranchQuestions(allBranchQuestions)

    } catch (error) {
      console.error("Ошибка при получении вопросов:", error);
    }
  };


  React.useEffect(() => {
    setPhoneToCopy(formInitialValues.clientPhone || "");
   
  }, [formInitialValues, setPhoneToCopy]);
  React.useEffect(() => {
  if(formInitialValues?.reviewQuestions){
    console.log("РАБОТАЕТ")
    const reviewQuestions=formInitialValues.reviewQuestions
    setCurrentQuestions(reviewQuestions.map(({currentIssues, answer})=>{
      return  {
        id: currentIssues.id,
        name: currentIssues.name,
        answerId:answer.id
      }
    }))
    
    setBranchID(formInitialValues.branchId)
  }
  }, [formInitialValues]);
  const handleReset = React.useCallback(() => {
    if (review) {
      form.setFieldsValue(formInitialValues);
      setPhoneToCopy(formInitialValues.clientPhone || "");
      setAttachments(getInitialReviewAttachments(review));
    }
  }, [review, form, formInitialValues, setAttachments, setPhoneToCopy]);

  React.useImperativeHandle(
    ref,
    () => ({
      resetForm: handleReset,
    }),
    [handleReset]
  );

  const handleFinish =
    (values: Store) => {
      console.log(values)
      const updatedReview = new Review();
      updatedReview.id = values.id;
      updatedReview.branchId = values.branchId;
      updatedReview.rate = values.rate;
      updatedReview.content = values.content;
      updatedReview.comment = values.comment;
      updatedReview.status = values.status;
      updatedReview.clientDisplayName = values.clientDisplayName;
      updatedReview.clientPhone = values.clientPhone
        ? String(values.clientPhone).trim()
        : values.clientPhone || null;
      updatedReview.clientEmail = values.clientEmail
        ? String(values.clientEmail).trim()
        : values.clientEmail || null;

      updatedReview.reviewDestinations = values.reviewDestinationTypeIds.map(
        (reviewDestinationTypeId: string) => {
          const reviewDestination = new ReviewDestination();
          const reviewDestinationId = review?.reviewDestinations?.find(
            (reviewDestination) =>
              reviewDestination.reviewDestinationTypeId === reviewDestinationTypeId
          )?.id;
          if (reviewDestinationId) {
            reviewDestination.id = reviewDestinationId;
          }
          reviewDestination.reviewId = updatedReview.id;
          reviewDestination.reviewDestinationTypeId = reviewDestinationTypeId;
          return reviewDestination;
        }
      );

      updatedReview.reviewTags = values.reviewTagTypeIds?.map((reviewTagTypeId: string) => {
        const reviewTag = new ReviewTag();
        const reviewTagId = review?.reviewTags?.find(
          (reviewTag) => reviewTag.reviewTagTypeId === reviewTagTypeId
        )?.id;
        if (reviewTagId) {
          reviewTag.id = reviewTagId;
        }
        reviewTag.reviewId = updatedReview.id;
        reviewTag.reviewTagTypeId = reviewTagTypeId;
        return reviewTag;
      });

      updatedReview.reviewServiceOrders = attachments.reviewServiceOrders as ReviewServiceOrder[];
      updatedReview.reviewWebLinks = attachments.reviewWebLinks as ReviewWebLink[];
      updatedReview.reviewClientCalls = attachments.reviewClientCalls as ReviewClientCall[];
      updatedReview.reviewClientEmails = attachments.reviewClientEmails as ReviewClientEmail[];
      updatedReview.reviewQuestions = currentQuestions
      console.log(updatedReview?.reviewQuestions)
      onSubmit(updatedReview);
    }
  
 

  // const getCurrentQuestions=():any[]=>{
  //   const currentQuestions:any[]=[]
  //   validIssues
  //     .filter(currentIssue => {
  //         // Извлекаем массив идентификаторов currentIssuesId из branchQuestions
  //         const branchQuestionIds = branchQuestions.map(bq => bq.currentIssuesId);

  //         // Проверяем, содержится ли currentIssue.id в массиве branchQuestionIds
  //         return branchQuestionIds.includes(currentIssue.id);
  //     })
  //     .map(currentIssue => {

  //     })
  //         return currentQuestions;  
  // }
  const collapseExtra = React.useCallback(
    (props: ReviewAttachmentsCollapseExtraPropProps) => (
      <Popconfirm
        title="Вы уверены?"
        okText="Удалить"
        okButtonProps={{ danger: true }}
        onConfirm={(event) => {
          if (event) {
            event.stopPropagation();
          }
          removeReviewAttachment(props.attachment, setAttachments);
        }}
        onCancel={(event) => {
          if (event) {
            event.stopPropagation();
          }
        }}
      >
        <DeleteOutlined
          onClick={(event) => {
            event.stopPropagation();
          }}
        />
      </Popconfirm>
    ),
    [setAttachments]
  );

  const handleAddAttachment = React.useCallback(
    (attachment: ReviewAttachment) => {
      if (review?.id) {
        attachment.reviewId = review.id;
      }

      if (attachment instanceof ReviewServiceOrder) {
        setAttachments((currentState) => {
          const newState = { ...currentState };
          if (newState.reviewServiceOrders) {
            newState.reviewServiceOrders = [...newState.reviewServiceOrders, attachment];
          } else {
            newState.reviewServiceOrders = [attachment];
          }
          if (attachment.serviceOrder) {
            if (!form.getFieldValue("clientDisplayName")) {
              form.setFields([
                { name: "clientDisplayName", value: attachment.serviceOrder.clientDisplayName },
              ]);
              form.validateFields(["clientDisplayName"]);
            }
            if (!form.getFieldValue("clientPhone")) {
              form.setFields([{ name: "clientPhone", value: attachment.serviceOrder.clientPhone }]);
              form.validateFields(["clientPhone"]);
            }
            if (!form.getFieldValue("branchId")) {
              const branch = branches.find(
                (branch) => branch.guid1c === attachment.serviceOrder?.branchGuid1c

              );
              setBranchID(branch?.id)
              form.setFields([{ name: "branchId", value: branch?.id }]);
              form.validateFields(["branchId"]);
            }
            // if(!form.getFieldValue("currentIssuedId")){
            //   form.setFields([{name: "currentIssues", value: currentsIssues.map(r=>r.id)}])
            //   form.validateFields(["currentIssues"])
            // }
          }
          return newState;
        });
      } else if (attachment instanceof ReviewWebLink) {
        setAttachments((currentState) => {
          const newState = { ...currentState };
          if (newState.reviewWebLinks) {
            newState.reviewWebLinks = [...newState.reviewWebLinks];
            newState.reviewWebLinks.push(attachment);
          } else {
            newState.reviewWebLinks = [attachment];
          }
          if (!form.getFieldValue("clientDisplayName") && attachment.clientNickname) {
            form.setFields([{ name: "clientDisplayName", value: attachment.clientNickname }]);
            form.validateFields(["clientDisplayName"]);
          }
          return newState;
        });
      } else if (attachment instanceof ReviewClientCall) {
        setAttachments((currentState) => {
          const newState = { ...currentState };
          if (newState.reviewClientCalls) {
            newState.reviewClientCalls = [...newState.reviewClientCalls];
            newState.reviewClientCalls.push(attachment);
          } else {
            newState.reviewClientCalls = [attachment];
          }
          if (!form.getFieldValue("clientPhone")) {
            form.setFields([{ name: "clientPhone", value: attachment.clientPhone }]);
            form.validateFields(["clientPhone"]);
          }
          return newState;
        });
      } else if (attachment instanceof ReviewClientEmail) {
        setAttachments((currentState) => {
          const newState = { ...currentState };
          if (newState.reviewClientEmails) {
            newState.reviewClientEmails = [...newState.reviewClientEmails];
            newState.reviewClientEmails.push(attachment);
          } else {
            newState.reviewClientEmails = [attachment];
          }
          if (!form.getFieldValue("clientEmail")) {
            form.setFields([{ name: "clientEmail", value: attachment.clientEmail }]);
            form.validateFields(["clientEmail"]);
          }
          if (!form.getFieldValue("content")) {
            form.setFields([{ name: "content", value: attachment.clientEmailContent }]);
            form.validateFields(["content"]);
          }
          return newState;
        });
      }

      setDrawerVisible(false);
    },
    [review, setDrawerVisible, form, branches]
  );



  return (
    <React.Fragment>
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        initialValues={formInitialValues}
        onFinish={handleFinish}
        size="large"
      >
        <Form.Item label="Приложения">
          <ReviewAttachmentCollapse
            reviewServiceOrders={attachments.reviewServiceOrders}
            reviewWebLinks={attachments.reviewWebLinks}
            reviewClientCalls={attachments.reviewClientCalls}
            reviewClientEmails={attachments.reviewClientEmails}
            extra={collapseExtra}
          />
          <AddButton style={{ marginTop: 8 }} onClick={() => setDrawerVisible(true)}>
            Добавить заказ-наряд, сайт, и т.д.
          </AddButton>
        </Form.Item>
        <Form.Item
          label="Клиент"
          name="clientDisplayName"
          rules={[{ required: true, message: "Это поле должно быть заполнено" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Телефон клиента"
          name="clientPhone"
          rules={[
            {
              type: "integer",
              transform: (value) => {
                if (!value) {
                  return 0;
                }
                return Number(value);
              },
              message: "Это поле должно содержать только цифры",
            },
            {
              type: "string",
              transform: (value) => String(value),
              max: 11,
              min: 3,
              message: "Номер телефона - 3-11 цифр",
            },
          ]}
          validateTrigger={"onBlur"}
        >
          <Input
            onChange={(event) => {
              const {
                target: { value },
              } = event;

              setPhoneToCopy(value);
            }}
            suffix={<Paragraph copyable={{ text: phoneToCopy }}></Paragraph>}
          />
        </Form.Item>
        <Form.Item
          label="Email-адрес клиента"
          name="clientEmail"
          rules={[{ type: "email", message: "Это поле должно содержать валидный email адрес" }]}
          validateTrigger={"onBlur"}
        >
          <Input />
        </Form.Item>



        <Form.Item
          label="Филиал"
          name="branchId"
          rules={[{ required: true, message: "Это поле должно быть заполнено" }]}
        >
          <Select
            placeholder="Выбрать"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={(value) => {
              setBranchID(value?.toString())
            }}
          >
            {branches.map((branch) => (
              <Option key={`review_${review?.id}_branch_${branch.id}`} value={branch.id}>
                {branch.city ? `${branch.name} (${branch.city.name})` : branch.name}
              </Option>
            ))}
          </Select>
        </Form.Item>



        <Form.Item
          name="reviewDestinationTypeIds"
          label="Направления"
          rules={[{ required: true, message: "Должно быть выбрано хотя бы одно направление" }]}
        >
          <CheckboxGroup
            options={reviewDestinationTypes.map((reviewDestinationType) => ({
              value: reviewDestinationType.id,
              label: reviewDestinationType.name,
            }))}
          />
        </Form.Item>
        <Form.Item
          label="Оценка"
          name="rate"
          rules={[
            {
              type: "integer",
              min: 0,
              max: 5,
            },
          ]}
          validateTrigger={"onBlur"}
        >
          <Rate
            onChange={(value) => {
              if (value >= 4) {
                form.setFields([{ name: "status", value: ReviewStatusEnum.CLOSED }]);
                form.validateFields(["status"]);
                if (!form.getFieldValue("content")) {
                  form.setFields([{ name: "content", value: "Клиента всё устроило." }]);
                  form.validateFields(["content"]);
                }
              } else if (value < 4 && value > 0) {
                form.setFields([{ name: "status", value: ReviewStatusEnum.PROCESSING }]);
                form.validateFields(["status"]);
              } else {
                form.resetFields(["status"]);
              }
            }}
          />
        </Form.Item>
        <Form.Item name="reviewTagTypeIds" label="Теги">
          <Select mode="multiple">
            {reviewTagTypes.map((reviewTagType) => (
              <Option
                key={`review_${review?.id}_reviewTagType_${reviewTagType.id}`}
                value={reviewTagType.id}
              >
                {reviewTagType.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {validIssues
          .filter(currentIssue => {
            // Извлекаем массив идентификаторов currentIssuesId из branchQuestions
            const branchQuestionIds = branchQuestions.map(bq => bq.currentIssuesId);
              console.log(branchQuestions.map(bg=>bg.currentIssues))
            // Проверяем, содержится ли currentIssue.id в массиве branchQuestionIds
            return branchQuestionIds.includes(currentIssue.id);
          })
          .map(currentIssue => {
           const selectValue=currentQuestions.length>0 ? currentQuestions?.find(v => v.id === currentIssue.id)?.answerId : undefined
            return (
              <Form.Item
                key={currentIssue.id}
                label={currentIssue.name}
                labelCol={{ span: 24 }} // Метка занимает всю ширину
                wrapperCol={{ span: 24 }} // Содержимое занимает всю ширину
                style={{ marginLeft: '20%', color: 'black', fontWeight: 'bold', marginInlineStart: '17.5%', width: '65%' }}
              >
                <Select
                  placeholder="Пожалуйста, выберите ответ"
                  showSearch
                  value={selectValue}
                
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  
                  }
                  onChange={(value) => {
                    console.log("selectValue", value)
                    const item = currentQuestions.find(v => v.id === currentIssue.id)
                    console.log("SelectItem", item);
                    if (item) {
                      console.log("ifItem");
                      const newQuestions = currentQuestions.map((gggg) => {
                        if (gggg.id == item.id) {
                          gggg.answerId = value
                        }
                        return gggg;
                      })
                      console.log("NewQuestions", newQuestions);
                      setCurrentQuestions(newQuestions)
                    } else {
                      console.log("AddNewItem")
                      setCurrentQuestions(prev => [
                        ...prev,
                        {
                          id: currentIssue.id,
                          name: currentIssue.name,
                          answerId: value?.toString()
                        }
                      ]);
                    }
                    // currentQuestions.push({
                    //   id:currentIssue.id,
                    //   name:currentIssue.name,
                    //   answerId:value?.toString()
                    // })
                  }}
                >
                  {answers
                    .filter(answer => answer.currentIssuesId === currentIssue.id) // Фильтрация ответов по currentIssuesId
                    .map(answer => (
                      <Option key={`currentIssues_${currentIssue.id}_answers_${answer.id}`} value={answer.id}>
                        {`${answer.answer} (${answer.typeAnswer})`}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            )
          })}





        <Form.Item
          name="content"
          label="Отзыв клиента"
          validateTrigger={"onBlur"}
          rules={[{ required: contentIsRequired, message: "Это поле должно быть заполнено" }]}
        >
          <AntdFormReactQuillInput placeholder="То, что говорит/пишет клиент." />
        </Form.Item>
        <Form.Item
          name="comment"
          label={
            <div>
              Комментарий
              <br />
              оператора СКК
            </div>
          }
        >
          <AntdFormReactQuillInput placeholder="Примечания для руководства, похвала сотрудников и т.д." />
        </Form.Item>
        <Form.Item
          name="status"
          rules={[{ required: true, message: "Это поле должно быть заполнено" }]}
          label={
            <div>
              Статус
              <br />
              после сохранения
            </div>
          }
        >
          <Select placeholder="Выбрать">
            {Object.keys(ReviewStatusEnum).map((reviewStatus) => (
              <Option
                key={`review_${review?.id}_reviewStatusView_${reviewStatus}`}
                value={reviewStatus}
              >
                <ReviewStatus status={reviewStatus as keyof typeof ReviewStatusEnum} />
              </Option>
            ))}
          </Select>
        </Form.Item>




        <Form.Item wrapperCol={{ offset: 4 }}>
          <Space>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Space>
        </Form.Item>
      </Form>
      <AddReviewAttachmentDrawer
        currentReviewServiceOrders={attachments.reviewServiceOrders}
        visible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        onFinish={handleAddAttachment}
      />
    </React.Fragment>
  );
});

export default ReviewForm;
