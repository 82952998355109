import React, { useState } from "react";
import { PageFunctionComponent } from "libs/interfaces/page-function-component";
import ReviewsPage from "pages/reviews/reviews.page";
import { useAppDispatch, useAppSelector, useSetTitleEffect } from "store/hooks";
import { Button, Form, PageHeader, Table } from "antd";
import ResetButton from "components/buttons/reset-button";
import RefreshButton from "components/buttons/refresh-button";
import ReviewStatus from "components/common/review-status";
import { ReviewStatusEnum } from "libs/enums/review-status.enum";
import { DEFAULT_TABLE_SORTER } from "pages/reviews/reviews.constants";
import { Review } from "services/review/review.service";
import { useTableState } from "hooks/use-table-state";
import { useTableChangeHandler } from "hooks/use-table-change-handler";
import fetchReviews from "./helpers/fetch-review-questions";
import getColumns from "./helpers/get-columns";
import useComponentDidMountEffect from "hooks/use-component-did-mount-effect";
import { useTableSorterCleaner } from "hooks/use-table-sorter-cleaner";
import { useTableFilterCleaner } from "hooks/use-table-filter-cleaner";
import useAsyncActionState from "hooks/use-async-action-state";
import { setCheckReviewQuestionsTableState, setCreatingReviewsTableState } from "store/actions/latest-table-states";
import convertTableParamsToQueryParams from "../all/helpers/convert-table-params-to-query-params";
import { ReviewQuestions } from "services/review/models/review-questions.model";
import { SortOrder, ColumnsType } from "antd/es/table/interface";
import fetchBranches from "pages/reviews/helpers/fetch-branches";
import { Branch } from "services/branch/models/branch";
import { type } from "node:os";
import { ReminderTableSimpleFormDto } from "components/tables/ReminderTable/ReminderTableSimpleForm";
import search from "antd/es/transfer/search";

const filterDataFetcher = () =>
  
    fetchBranches()
   
  
const CheckReviewQuestionsPage: PageFunctionComponent = () => {
  useSetTitleEffect([ReviewsPage.label]);
  const CheckReviewQuestionsPage = useAppSelector(
    (state) => state.latestTableStates.checkReviewQuestionsPage
  );
  const dispatch = useAppDispatch();
  const [state,  setStateAsync] = useState<ReviewQuestions[] |undefined>(
    undefined,
   
  );
  const [columns, setColumns]=useState< ColumnsType<ReviewQuestions>| undefined>(undefined) 
  const [form] = Form.useForm<ReminderTableSimpleFormDto>();
  // const { type, changeType, submit, reset } = search || {};
  const [fetchFilterData, { loading: filterDataLoading, result: filterData = [] }] =
  useAsyncActionState(filterDataFetcher);

  // const loading = state
  React.useEffect(()=>{
   fetchReviews().then((data)=>{
        if(data){
            setColumns(getColumns(data,filterData))
            
            setStateAsync(data)
          
        }
   })
    
        
  },[])

//   const handleTableChange = useTableChangeHandler(
//     state,
//     setStateAsync,
//     convertTableParamsToQueryParams,
//     fetchReviews,
//     DEFAULT_TABLE_SORTER
//   );
//   const [canClearSorter, clearSorter] = useTableSorterCleaner(
//     state,
//     handleTableChange,
//     DEFAULT_TABLE_SORTER
//   );
//   const [canClearFilter, clearFilter] = useTableFilterCleaner(state, handleTableChange, {});
  
//   const columns = React.useMemo(() => getColumns(state), [state]);

//   useComponentDidMountEffect(() => {
//     handleTableChange(state.pagination, state.filters, state.sorter, {
//       currentDataSource: state.data,
//       action: "paginate",
//     });

//   });

  

//   React.useEffect(() => {
//     dispatch(setCheckReviewQuestionsTableState(state));
//     console.log(state)
//   }, [dispatch, state]);

//   const loading = state.loading;

  return (
    <PageHeader
      title="Ответы на актуальные вопросы"
     
      extra={
        <React.Fragment>
          {/* <ResetButton onClick={clearFilter} disabled={!canClearFilter || loading}>
            Сбросить фильтры
          </ResetButton>
          <ResetButton onClick={clearSorter} disabled={!canClearSorter || loading}>
            Сбросить сортировки
          </ResetButton> */}
          {/* <RefreshButton
            onClick={() =>
              handleTableChange(state.pagination, state.filters, state.sorter, {
                currentDataSource: state.data,
                action: "paginate",
              })
            }
            loading={loading}
          /> */}
        </React.Fragment>
      }
    >


      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={state}
       
        //loading={{ spinning: loading, delay: 500 }}
         //onChange={handleTableChange}
        tableLayout="auto"
        scroll={{ x: 1280 }}
      />
    </PageHeader>
  );

};

CheckReviewQuestionsPage.path = "/check-review-questions";
CheckReviewQuestionsPage.label = "Актуальные вопросы";

export default CheckReviewQuestionsPage;
