import React from "react";
import { useHistory } from "react-router-dom";
import { useAntdTable } from "ahooks";
import { Form, PageHeader, Row, Col, Button } from "antd";
import { PageFunctionComponent } from "libs/interfaces/page-function-component";
import { useSetTitleEffect } from "store/hooks";
import SettingsPage from "pages/settings/settings.page";
import ContentLayout from "components/layouts/content.layout";
import Breadcrumbs from "components/common/breadcrumbs";
import useGoBack from "hooks/use-go-back";
import CurrentIssuesTable from "components/tables/CurrentIssuesTable/CurrentIssuesTable";
import CurrentIssuesTableSimpleForm, { CurrentIssuesTableSimpleFormDto } from "components/tables/CurrentIssuesTable/CurrentIssuesTableSimpleForm";
import { getCurrentIssuesTableData, GetCurrentIssuesTableData } from "./get-current-issues-table-data";
import AddButton from "components/buttons/add-button";
import RefreshButton from "components/buttons/refresh-button";

import AddBranchSettingsPage from "../add-branch/add-branch.page";
import { Subject } from "abilities/subject.enum";
import { subjectAction } from "abilities/subject-action.constant";
import CheckAbilities from "store/components/CheckAbilities";
import AddCurrentIssuesSettingsPage from "../add-current-issues/add-current-issues.page";

const CurrentsIssuesSettingsPage: PageFunctionComponent = () => {
  useSetTitleEffect([SettingsPage.label, CurrentsIssuesSettingsPage.label]);

  const goBack = useGoBack();
  const history = useHistory();
 const [form] = Form.useForm<CurrentIssuesTableSimpleFormDto>();

  const { tableProps, loading, refresh, search, params } = useAntdTable(getCurrentIssuesTableData, {
    form,
    defaultType: "simple",
    cacheKey: "CurrentsIssuesPageTable",
    cacheTime: 10 * 1000,
    loadingDelay: 500,
    defaultParams: [
      {
        current: 1,
        pageSize: 10,
        sorter: { field: "name", order: "ascend" },
      },
    ],
  });

  const { type, changeType, submit, reset } = search || {};
  const { sorter = {} } = params[0] || ({} as any);

  return (
     <ContentLayout>
        
      <Breadcrumbs items={[SettingsPage.label, CurrentsIssuesSettingsPage.label]} />
      <PageHeader
        title={CurrentsIssuesSettingsPage.label}
        extra={
          <Row gutter={[8, 8]}>
            <CheckAbilities
              abilities={[{ subject: Subject.BRANCH, action: subjectAction.BRANCH.CREATE }]}
              placeholder={null}
              loading={loading}
            >
              <Col>
                <AddButton
                  onClick={() =>
                    history.push({
                      pathname: `${SettingsPage.path}${AddCurrentIssuesSettingsPage.path}`,
                      state: { canGoBack: true },
                    })
                  }
                >
                  Добавить
                </AddButton>
              </Col>
            </CheckAbilities>
            <Col>
              <RefreshButton loading={loading} onClick={refresh} />
            </Col>
          </Row>
        }
        onBack={goBack}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {type === "advance" ? null : (
            <CurrentIssuesTableSimpleForm form={form} submit={submit} reset={reset} />
          )}
          <Button type="link" onClick={changeType} hidden>
            {type === "simple" ? "Расширенный поиск" : "Простой поиск"}
          </Button>
        </div>

        <CurrentIssuesTable tableProps={tableProps} sorter={sorter} />
      </PageHeader>
    </ContentLayout>
  );
};

CurrentsIssuesSettingsPage.path = "/currents-issues";
CurrentsIssuesSettingsPage.label = "Актуальные вопросы";

export default CurrentsIssuesSettingsPage;
