import { Type, plainToClass } from "class-transformer";
import { PartialDeep } from "type-fest";
import { RequestQueryBuilder, CreateQueryParams } from "@nestjsx/crud-request";
import ApiDriver from "libs/common/api-driver/api-driver";
import { PagiantedResponse } from "services/interfaces/paginated-response.interface";
import { User } from "services/user/models/user";
import { CurrentIssues } from "./models/current-issues";
import { CurrentIssuesUpsertDto } from "./dtos/current-issues-upsert-dto.type";

// export class CurrentIssuesHeads {
//   id!: string;
//   name!: string;
//   responseType!: string;
//   branchName!: string;
//   createByUserId:string;

//   @Type(() => Date)
//   createDate!: Date;

//   @Type(() => Date)
//   deleteDate!: Date;

//   // @Type(() => User)
//   // createdByUser: User;
// }

export class CurrentIssuesService {

  constructor(private readonly apiDriver: ApiDriver) {}
  async findAll(params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    let response = await this.apiDriver.get<PagiantedResponse<CurrentIssues> | CurrentIssues[]>("/current-issues", {
      params: queryParams,
    });
    if (Array.isArray(response)) {
      response = plainToClass(CurrentIssues, response);
    } else {
      response.data = plainToClass(CurrentIssues, response.data);
    }
    return response;
  }

  async findOne(id: CurrentIssues["id"], params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    const response = await this.apiDriver.get<CurrentIssues>(`/current-issues/${id}`, {
      params: queryParams,
    });
    return plainToClass(CurrentIssues, response);
  }

  async createOne(dto: CurrentIssuesUpsertDto) {
    const user = await this.apiDriver.post<CurrentIssues>("/current-issues", dto);
    return plainToClass(CurrentIssues, user);
  }

  async updateOne(id: string, dto: CurrentIssuesUpsertDto) {
    const updated = await this.apiDriver.patch<CurrentIssues>(`/current-issues/${id}`, dto);
    return plainToClass(CurrentIssues, updated);
  }

  async deleteOne(id: string, params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    const deleted = await this.apiDriver.delete<CurrentIssues>(`/current-issues/${id}`, {
      params: queryParams,
    });
    return plainToClass(CurrentIssues, deleted);
  }

}
