import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { TeamOutlined, TagsOutlined, KeyOutlined } from "@ant-design/icons";
import { PageFunctionComponent } from "libs/interfaces/page-function-component";
import usePath from "hooks/use-path";
import SideNavigation from "components/common/side-navigation";
import NotFoundErrorPage from "pages/not-found-error/not-found-error.page";
import AddBranchSettingsPage from "./pages/add-branch/add-branch.page";
import AddCitySettingsPage from "./pages/add-city/add-city.page";
import AddRoleSettingsPage from "./pages/add-role/add-role.page";
import AddUserSettingsPage from "./pages/add-user/add-user.page";
import BranchesSettingsPage from "./pages/branches/branches.page";
import BranchSettingsPage from "./pages/branch/branch.page";
import CitiesSettingsPage from "./pages/cities/cities.page";
import CitySettingsPage from "./pages/city/city.page";
import IntegrationKeysSettingsPage from "./pages/integration-keys/integration-keys.page";
import RoleSettingsPage from "./pages/role/role.page";
import RolesSettingsPage from "./pages/roles/roles.page";
import UserSettingsPage from "./pages/user/user.page";
import UsersSettingsPage from "./pages/users/users.page";
import ReviewDestinationTypesSettingsPage from "./pages/review-destination-types/review-destination-types.page";
import ReviewSitesSettingsPage from "./pages/review-sites/review-sites.page";
import ReviewTagTypesSettingsPage from "./pages/review-tag-types/review-tag-types.page";
import AddReviewDestinationTypeSettingsPage from "./pages/add-review-destination-type/add-review-destination-type.page";
import AddReviewSiteSettingsPage from "./pages/add-review-site/add-review-site.page";
import AddReviewTagTypeSettingsPage from "./pages/add-review-tag-type/add-review-tag-type.page";
import ReviewDestinationTypeSettingsPage from "./pages/review-destination-type/review-destination-type.page";
import ReviewSiteSettingsPage from "./pages/review-site/review-site.page";
import ReviewTagTypeSettingsPage from "./pages/review-tag-type/review-tag-type.page";
import IntegrationKeySettingsPage from "./pages/integration-key/integration-key.page";
import AddIntegrationKeySettingsPage from "./pages/add-integration-key/add-integration-key.page";
import { SideNavigationItem } from "libs/interfaces/side-navigation-item.interface";
import { useSettingsPageAccessControl } from "./hooks/use-settings-page-access-control";
import CheckAbilities from "store/components/CheckAbilities";
// import CurrentIssuesPage from "pages/current-issues/currentIssues.page";
import { access } from "fs";
import CurrentIssuesSettingsPage from "./pages/current-issues/currentIssues.page";
import CurrentsIssuesSettingsPage from "./pages/currents-issues/currents-issues.page";
import AddCurrentIssuesSettingsPage from "./pages/add-current-issues/add-current-issues.page";

const SettingsPage: PageFunctionComponent = () => {
  const path = usePath(2);

  const pageAccessControl = useSettingsPageAccessControl();

  const sideNavigationItems = React.useMemo(() => {
    const items: SideNavigationItem[] = [];

    if (pageAccessControl.UsersSettingsPage) {
      items.push({
        key: `${SettingsPage.path}${UsersSettingsPage.path}`,
        pathname: `${SettingsPage.path}${UsersSettingsPage.path}`,
        icon: <TeamOutlined />,
        label: UsersSettingsPage.label,
      });
    }

    if (pageAccessControl.RolesSettingsPage) {
      items.push({
        key: `${SettingsPage.path}${RolesSettingsPage.path}`,
        pathname: `${SettingsPage.path}${RolesSettingsPage.path}`,
        label: RolesSettingsPage.label,
      });
    }

    if (pageAccessControl.CitiesSettingsPage) {
      items.push({
        key: `${SettingsPage.path}${CitiesSettingsPage.path}`,
        pathname: `${SettingsPage.path}${CitiesSettingsPage.path}`,
        label: CitiesSettingsPage.label,
      });
    }

    if (pageAccessControl.BranchesSettingsPage) {
      items.push({
        key: `${SettingsPage.path}${BranchesSettingsPage.path}`,
        pathname: `${SettingsPage.path}${BranchesSettingsPage.path}`,
        label: BranchesSettingsPage.label,
      });
    }

    if (pageAccessControl.ReviewDestinationTypesSettingsPage) {
      items.push({
        key: `${SettingsPage.path}${ReviewDestinationTypesSettingsPage.path}`,
        pathname: `${SettingsPage.path}${ReviewDestinationTypesSettingsPage.path}`,
        label: ReviewDestinationTypesSettingsPage.label,
      });
    }

    if (pageAccessControl.ReviewSitesSettingsPage) {
      items.push({
        key: `${SettingsPage.path}${ReviewSitesSettingsPage.path}`,
        pathname: `${SettingsPage.path}${ReviewSitesSettingsPage.path}`,
        label: ReviewSitesSettingsPage.label,
      });
    }
    if (pageAccessControl.CurrentsIssuesSettingsPage) {
      console.log("КРУТО")
      items.push({
        key: `${SettingsPage.path}${CurrentsIssuesSettingsPage.path}`,
        pathname: `${SettingsPage.path}${CurrentsIssuesSettingsPage.path}`,
        label: CurrentsIssuesSettingsPage.label,
      });
    }

    if (pageAccessControl.ReviewTagTypesSettingsPage) {
      items.push({
        key: `${SettingsPage.path}${ReviewTagTypesSettingsPage.path}`,
        pathname: `${SettingsPage.path}${ReviewTagTypesSettingsPage.path}`,
        icon: <TagsOutlined />,
        label: ReviewTagTypesSettingsPage.label,
      });
    }

    if (pageAccessControl.IntegrationKeysSettingsPage) {
      items.push({
        key: `${SettingsPage.path}${IntegrationKeysSettingsPage.path}`,
        pathname: `${SettingsPage.path}${IntegrationKeysSettingsPage.path}`,
        icon: <KeyOutlined />,
        label: IntegrationKeysSettingsPage.label,
      });
    }

    // if (pageAccessControl.CurrentIssuesSettingsPage) {
    //   items.push({
    //     key: `${SettingsPage.path}${CurrentIssuesSettingsPage.path}`,
    //     pathname: `${SettingsPage.path}${CurrentIssuesSettingsPage.path}`,
    //     icon: <KeyOutlined />,
    //     label: CurrentIssuesSettingsPage.label,
    //   });
    // }
  


    console.log("СТРАНИЦЫ",items)
    return items;
  }, [pageAccessControl]);

  const pages = [
    { access: pageAccessControl.AddBranchSettingsPage, component: AddBranchSettingsPage },
    { access: pageAccessControl.AddBranchSettingsPage, component: AddBranchSettingsPage },
    { access: pageAccessControl.AddCurrentIssuesSettingsPage, component: AddCurrentIssuesSettingsPage },
    { access: pageAccessControl.AddCurrentIssuesSettingsPage, component: AddCurrentIssuesSettingsPage },
    { access: pageAccessControl.AddCitySettingsPage, component: AddCitySettingsPage },
    {
      access: pageAccessControl.AddIntegrationKeySettingsPage,
      component: AddIntegrationKeySettingsPage,
    },
    {
      access: pageAccessControl.AddReviewDestinationTypeSettingsPage,
      component: AddReviewDestinationTypeSettingsPage,
    },
    { access: pageAccessControl.AddReviewSiteSettingsPage, component: AddReviewSiteSettingsPage },
    {
      access: pageAccessControl.AddReviewTagTypeSettingsPage,
      component: AddReviewTagTypeSettingsPage,
    },
    { access: pageAccessControl.AddRoleSettingsPage, component: AddRoleSettingsPage },
    { access: pageAccessControl.AddUserSettingsPage, component: AddUserSettingsPage },
    { access: pageAccessControl.BranchesSettingsPage, component: BranchesSettingsPage },
    { access: pageAccessControl.BranchSettingsPage, component: BranchSettingsPage },
    { access: pageAccessControl.CitiesSettingsPage, component: CitiesSettingsPage },
    { access: pageAccessControl.CitySettingsPage, component: CitySettingsPage },

    {access :pageAccessControl.CurrentIssuesSettingsPage, component: CurrentIssuesSettingsPage},
    {access :pageAccessControl.CurrentsIssuesSettingsPage, component: CurrentsIssuesSettingsPage},

    
   { access: pageAccessControl.IntegrationKeySettingsPage, component: IntegrationKeySettingsPage },
    {
      access: pageAccessControl.IntegrationKeysSettingsPage,
      component: IntegrationKeysSettingsPage,
    },
    {
      access: pageAccessControl.ReviewDestinationTypeSettingsPage,
      component: ReviewDestinationTypeSettingsPage,
    },
    {
      access: pageAccessControl.ReviewDestinationTypesSettingsPage,
      component: ReviewDestinationTypesSettingsPage,
    },
    { access: pageAccessControl.ReviewSiteSettingsPage, component: ReviewSiteSettingsPage },
    { access: pageAccessControl.ReviewSitesSettingsPage, component: ReviewSitesSettingsPage },
    { access: pageAccessControl.ReviewTagTypeSettingsPage, component: ReviewTagTypeSettingsPage },
    { access: pageAccessControl.ReviewTagTypesSettingsPage, component: ReviewTagTypesSettingsPage },
    { access: pageAccessControl.RoleSettingsPage, component: RoleSettingsPage },
    { access: pageAccessControl.RolesSettingsPage, component: RolesSettingsPage },
    { access: pageAccessControl.UserSettingsPage, component: UserSettingsPage },
    { access: pageAccessControl.UsersSettingsPage, component: UsersSettingsPage },
  ];

  return (
    <React.Fragment>
      <SideNavigation selectedKey={path} items={sideNavigationItems} />
      <Switch>
        <Route exact path={SettingsPage.path}>
          <Redirect to={`${SettingsPage.path}${UsersSettingsPage.path}`} />
        </Route>
        {pages.map((page) => {
          return (
            <Route
              key={`SettingsPage_${page.component.name}`}
              path={`${SettingsPage.path}${page.component.path}`}
            >
              <CheckAbilities manual={page.access}>
                <page.component />
              </CheckAbilities>
            </Route>
          );
        })}
        <Route path={`${SettingsPage.path}${NotFoundErrorPage.path}`}>
          <NotFoundErrorPage />
        </Route>
      </Switch>
    </React.Fragment>
  );
};

SettingsPage.path = "/settings";
SettingsPage.label = "Настройки";

export default SettingsPage;
