import { Data, Params, Service } from "ahooks/lib/useAntdTable/types";
import { CreateQueryParams, RequestQueryBuilder } from "@nestjsx/crud-request";
import { plainToClass } from "class-transformer";
import ApiDriver from "libs/common/api-driver/api-driver";
import { convertTableSorterToQuerySorter } from "libs/helpers/convert-table-sorter-to-query-sorter";
import { PagiantedResponse } from "services/interfaces/paginated-response.interface";
import { AnswersUpsertDto } from "./dtos/answers-upsert-dto.type";
import { Answers } from "./models/answers";

export class AnswersService {
//   static deleteOne(currentIssuesId: string, id: string): Promise<Answers> {
//       throw new Error("Method not implemented.");
//   }
  constructor(private readonly apiDriver: ApiDriver) {}

  async findAll(currentIssuesId: Answers["currentIssuesId"], params: CreateQueryParams = {}) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    let response = await this.apiDriver.get<PagiantedResponse<Answers> | Answers[]>(
      `/current-issues/${currentIssuesId}/answers`,
      { params: queryParams }
    );
    if (Array.isArray(response)) {
      response = plainToClass(Answers, response);
    } else {
      response.data = plainToClass(Answers, response.data);
    }
    return response;
  }

  async findOne(
    currentIssuesId: Answers["currentIssuesId"],
    id: Answers["id"],
    params: CreateQueryParams = {}
  ) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    const response = await this.apiDriver.get<Answers>(`current-issues/${currentIssuesId}/answers/${id}`, {
      params: queryParams,
    });
    return plainToClass(Answers, response);
  }

  async createOne(currentIssuesId: Answers["currentIssuesId"], dto: AnswersUpsertDto) {
    const role = await this.apiDriver.post<Answers>(`current-issues/${currentIssuesId}/answers`, dto);
    return plainToClass(Answers, role);
  }

  async updateOne(currentIssuesId: Answers["currentIssuesId"], id: Answers["id"], dto: AnswersUpsertDto) {
    const updated = await this.apiDriver.patch<Answers>(
      `/current-issues/${currentIssuesId}/answers/${id}`,
      dto
    );
    return plainToClass(Answers, updated);
  }

  async deleteOne(
    currentIssuesId: Answers["currentIssuesId"],
    id: Answers["id"],
    params: CreateQueryParams = {}
  ) {
    const queryParams = RequestQueryBuilder.create(params).queryObject;
    const deleted = await this.apiDriver.delete<Answers>(`/current-issues/${currentIssuesId}/answers/${id}`, {
      params: queryParams,
    });
    return plainToClass(Answers, deleted);
  }





  getAnswersTableDataGetter(currentIssuesId: Answers["currentIssuesId"]): Service<Data, Params> {
    return async (paginatedParams, formData: Record<string, unknown>) => {
      const tableQuerySorter = convertTableSorterToQuerySorter<Answers>(paginatedParams.sorter);

      const answers = await this.findAll(currentIssuesId, {
        page: paginatedParams.current,
        limit: paginatedParams.pageSize,
        sort: tableQuerySorter
          ? [tableQuerySorter, { field: "id", order: "ASC" }]
          : { field: "id", order: "ASC" },
        join: [["currentIssues"]],
      });

      if (Array.isArray(answers)) {
        return {
          list: answers,
          total: answers.length,
        };
      } else {
        return {
          list: answers.data,
          total: answers.total,
        };
      }
    };
  }
}
