import React from "react";
import { useAntdTable, useRequest } from "ahooks";
import { Row, Col, PageHeader, Spin, Tag, Popconfirm, message } from "antd";
import { Answers } from "services/answers/models/answers";
import { AnswersUpsertDto } from "services/answers/dtos/answers-upsert-dto.type";
import { AnswersService } from "services/answers/answers.service";
import { answersService } from "services";
import AnswersTable from "./AnswersTable";
import AddButton from "components/buttons/add-button";
import RefreshButton from "components/buttons/refresh-button";
import AnswersForm, { AnswersFormProps } from "./AnswersForm";
import { ColumnsType } from "antd/es/table";
import RemoveButton from "components/buttons/remove-button";
import { ApiDriverHttpError } from "libs/common/api-driver/api-driver";
import { useAppSelector } from "store/hooks";
import { pageAccessControlSelector } from "store/selectors/page-access-control";
import checkAbilities from "store/helpers/check-abilities";
import { Subject } from "abilities/subject.enum";
import { subjectAction } from "abilities/subject-action.constant";


const createAnswers: AnswersService["createOne"] = (currentIssuesId, dto) => {
  const dtoCopy: AnswersUpsertDto = {...dto, currentIssuesId };
  return answersService.createOne(currentIssuesId, dtoCopy);
};

const deleteAnswers: AnswersService["deleteOne"] = (currentIssuesId, id) =>
  answersService.deleteOne(currentIssuesId, id);

export interface AnswersRelationProps {
  currentIssuesId?: Answers["currentIssuesId"];
  loading?: boolean;
  buttonsDisabled?: boolean;
  title?: string;
}

const AnswersRelation: React.FC<AnswersRelationProps> = (props) => {
  const {
    currentIssuesId,
    loading: loadingProp = false,
    buttonsDisabled = false,
    title = "Ответы на вопрос",
  } = props;

  const pageAccessControlState = useAppSelector(pageAccessControlSelector);

  const accessControl = React.useMemo(() => {
    if (pageAccessControlState.loading) {
      return {
        update: false,
        delete: false,
      };
    }
    return {
      update: checkAbilities(pageAccessControlState.abilities, {
        subject: Subject.CURRENT_ISSUES,
        action: subjectAction.CURRENT_ISSUES.UPDATE_ALL,
      }),
      delete: checkAbilities(pageAccessControlState.abilities, {
        subject: Subject.CURRENT_ISSUES,
        action: subjectAction.CURRENT_ISSUES.DELETE_ALL,
      }),
    };
  }, [pageAccessControlState]);

  const [addAnswers, setAddAnswers] = React.useState<boolean>(false);

  const getTableData = React.useMemo(() => {
    if (!currentIssuesId) {
      return async () => {
        return {
          list: [],
          total: 0,
        };
      };
    }

    return answersService.getAnswersTableDataGetter(currentIssuesId);
  }, [currentIssuesId]);

  const {
    tableProps,
    loading: getTableDataLoading,
    refresh,
    params,
  } = useAntdTable(getTableData, {
    defaultType: "simple",
    cacheKey: `AnswersTable-${currentIssuesId}`,
    cacheTime: 10 * 1000,
    loadingDelay: 500,
    defaultParams: [
      {
        current: 1,
        pageSize: 10,
        sorter: {},
      },
    ],
    refreshDeps: [currentIssuesId],
  });

  const { sorter = {} } = params[0] || ({} as any);

  const { run: runCreateAnswers, loading: createAnswersLoading } = useRequest(
    createAnswers,
    {
      manual: true,
      onSuccess: () => {
        setAddAnswers(false);
        refresh();
      },
      onError: (e) => {
        if (e instanceof ApiDriverHttpError) {
          if (e.status === 409) {
            message.error("Такой ответ уже существует!");
          }
        }
      },
    }
  );

  const { run: runDeleteAnswers, loading: deleteAnswersLoading } = useRequest(
    deleteAnswers,
    {
      manual: true,
      onSuccess: () => {
        setAddAnswers(false);
        refresh();
      },
      onError: () => refresh(),
    }
  );

  const handleAnswersFormFinish: Required<AnswersFormProps>["onFinish"] = React.useCallback(
    (dto) => {
      if (currentIssuesId) {
        runCreateAnswers(currentIssuesId, dto);
      }
    },
    [currentIssuesId, runCreateAnswers]
  );

  const handleDelete = React.useCallback(
    async (AnswersId: Answers["id"]) => {
      if (!AnswersId || !currentIssuesId) {
        return;
      }
      await runDeleteAnswers(currentIssuesId, AnswersId);
    },
    [runDeleteAnswers, currentIssuesId]
  );

  const roleAnswersTableRightExtra: ColumnsType<Answers> = React.useMemo(() => {
    if (!accessControl.delete) {
      return [];
    }

    return [
      {
        title: "",
        dataIndex: "_",
        key: "_",
        ellipsis: true,
        align: "center",
        fixed: "right",
        render: (_: any, record) => (
          <Popconfirm
            title="Вы уверены? Это действие будет нельзя отменить."
            okText="Удалить"
            okButtonProps={{ danger: true }}
            onConfirm={() => handleDelete(record.id)}
          >
            <RemoveButton
              disabled={getTableDataLoading || createAnswersLoading}
              loading={deleteAnswersLoading}
            />
          </Popconfirm>
        ),
      },
    ];
  }, [
    getTableDataLoading,
    createAnswersLoading,
    deleteAnswersLoading,
    handleDelete,
    accessControl,
  ]);

  if (!currentIssuesId) {
    return null;
  }

  return (
    <PageHeader
      title={title}
      subTitle={<Tag color="warning">Вносимые изменения вступят в силу в течение 10 минут</Tag>}
      extra={
        <Row gutter={[8, 8]}>
          {accessControl.update ? (
            <Col>
              <AddButton
                disabled={buttonsDisabled}
                danger={addAnswers}
                onClick={() => setAddAnswers(!addAnswers)}
              >
                {addAnswers ? "Отмена" : "Добавить"}
              </AddButton>
            </Col>
          ) : null}
          <Col>
            <RefreshButton
              disabled={buttonsDisabled}
              loading={getTableDataLoading}
              onClick={refresh}
            />
          </Col>
        </Row>
      }
    >
      <Spin spinning={loadingProp} delay={500}>
        {addAnswers && (
          <div style={{ marginBottom: "24px" }}>
            <Spin spinning={createAnswersLoading || deleteAnswersLoading}>
              <AnswersForm
                title="Добавить ответ на вопрос"
                initialValues={{ currentIssuesId }}
                disabledFields={{ currentIssuesId: true }}
                onFinish={handleAnswersFormFinish}
              />
            </Spin>
          </div>
        )}

        <AnswersTable
          tableProps={tableProps}
          sorter={sorter}
          size="small"
          rightExtra={roleAnswersTableRightExtra}
        />
      </Spin>
    </PageHeader>
  );
};

export default AnswersRelation;
