export enum Subject {
  REVIEW = "REVIEW",
  SERVICE_ORDER = "SERVICE_ORDER",
  BLACKLIST_CLIENT = "BLACKLIST_CLIENT",
  BRANCH = "BRANCH",
  USER = "USER",
  REVIEW_SITE = "REVIEW_SITE",
  REVIEW_TAG_TYPE = "REVIEW_TAG_TYPE",
  INTEGRATION_KEY = "INTEGRATION_KEY",
  BACKEND_CACHE = "BACKEND_CACHE",
  CITY = "CITY",
  ROLE = "ROLE",
  REVIEW_DESTINATION_TYPE = "REVIEW_DESTINATION_TYPE",
  STATISTICS = "STATISTICS",
  REMINDER = "REMINDER",
  CURRENT_ISSUES="CURRENT_ISSUES",
}
