import React from "react";
import { Form, Input, Descriptions, Button, Space } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { Store } from "antd/es/form/interface";
import { City } from "services/city/models/city";
import { CityUpsertDto } from "services/city/dtos/city-upsert-dto.type";
import { displayDateTime } from "libs/helpers/display-date-time";
import { noOp } from "libs/helpers/no-op";

export type CityFormDto = CityUpsertDto;

export interface CityFormProps {
  form?: FormInstance<CityFormDto>;
  onFinish?: (dto: CityUpsertDto, entityToUpdate?: City) => void;
  entityToUpdate?: City;
  initialValues?: Partial<CityFormDto>;
  disabledFields?: { [key in keyof CityFormDto]?: boolean };
  title?: string;
}

const COLUMN = { xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 };

const CityForm: React.FC<CityFormProps> = (props) => {
  const {
    form: formProp,
    onFinish = noOp,
    entityToUpdate,
    initialValues: initialValuesProp,
    title = "Город",
    disabledFields,
  } = props;

  const [form] = Form.useForm(formProp);

  const reset = React.useCallback(() => {
    form.resetFields();
  }, [form]);

  const initialValues: Store | undefined = React.useMemo(() => {
    const iV: Store = {};

    if (entityToUpdate) {
      Object.assign(iV, entityToUpdate);
    }

    if (initialValuesProp) {
      Object.assign(iV, initialValuesProp);
    }

    return iV;
  }, [entityToUpdate, initialValuesProp]);

  const handleFinish = React.useCallback(
    (dto: CityFormDto) => {
      console.log("Данные",dto, entityToUpdate)
      onFinish(dto, entityToUpdate);
    },
    [onFinish, entityToUpdate]
  );

  return (
    <Form form={form} initialValues={initialValues} onFinish={handleFinish}>
      <Descriptions title={title} column={COLUMN}>
        <Descriptions.Item label={<strong>Id</strong>}>{entityToUpdate?.id}</Descriptions.Item>
        <Descriptions.Item>
          <Form.Item
            id="name"
            name="name"
            label={<strong>Название</strong>}
            style={{ marginBottom: 0, width: "100%" }}
            rules={[
              { required: true, message: "Это поле должно быть заполнено." },
              {
                type: "string",
                max: 256,
                message: "Максимальный размер строки - 256 символов.",
              },
            ]}
          >
            <Input maxLength={256} disabled={disabledFields?.name} />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item label={<strong>Дата обновления</strong>}>
          {displayDateTime(entityToUpdate?.updatedAt)}
        </Descriptions.Item>
        <Descriptions.Item label={<strong>Дата создания</strong>}>
          {displayDateTime(entityToUpdate?.createdAt)}
        </Descriptions.Item>
      </Descriptions>
      <Form.Item noStyle>
        <Space style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="primary" size="large" htmlType="submit">
            Сохранить
          </Button>
          <Button type="text" size="large" onClick={reset}>
            Сбросить
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default CityForm;
