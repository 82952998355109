import { manualEmailSendingService, reviewService } from "services";
import { Review } from "services/review/review.service";

const addReview = async (review: Review) => {
  const reviewToCreate = { ...review };
  reviewToCreate.reviewClientCalls = undefined;
  reviewToCreate.rate = reviewToCreate.rate === 0 ? null : reviewToCreate.rate;
  reviewToCreate.reviewChatMessages = undefined;
  reviewToCreate.reviewDestinations = undefined;
  reviewToCreate.reviewTags = undefined;
  reviewToCreate.reviewStatuses = undefined;
  reviewToCreate.reviewClientCalls = undefined;
  reviewToCreate.reviewClientEmails = undefined;
  reviewToCreate.reviewServiceOrders = undefined;
  reviewToCreate.reviewWebLinks = undefined;
  reviewToCreate.reviewQuestions=undefined;
  

  const createdReview = await reviewService.createOne(reviewToCreate);

  const tasks: Promise<any>[] = [];

  if (review.reviewClientCalls && review.reviewClientCalls.length > 0) {
    tasks.push(
      reviewService.createManyReviewClientCalls(
        createdReview.id,
        review.reviewClientCalls.map((item) => {
          item.reviewId = createdReview.id;
          return item;
        })
      )
    );
  }
  if (review.reviewClientEmails && review.reviewClientEmails.length > 0) {
    tasks.push(
      reviewService.createManyReviewClientEmails(
        createdReview.id,
        review.reviewClientEmails.map((item) => {
          item.reviewId = createdReview.id;
          return item;
        })
      )
    );
  }
  if (review.reviewDestinations && review.reviewDestinations.length > 0) {
    tasks.push(
      reviewService.setReviewDestinations(
        createdReview.id,
        review.reviewDestinations.map(
          (reviewDestinations) => reviewDestinations.reviewDestinationTypeId
        )
      )
    );
  }
  if (review.reviewServiceOrders && review.reviewServiceOrders.length > 0) {
    tasks.push(
      reviewService.createManyReviewServiceOrders(
        createdReview.id,
        review.reviewServiceOrders.map((reviewServiceOrder) => {
          reviewServiceOrder.serviceOrder = undefined;
          reviewServiceOrder.reviewId = createdReview.id;
          return reviewServiceOrder;
        })
      )
    );
  }
  if (review.reviewWebLinks && review.reviewWebLinks.length > 0) {
    tasks.push(
      reviewService.createManyReviewWebLinks(
        createdReview.id,
        review.reviewWebLinks
          .map((reviewWebLinks) => {
            reviewWebLinks.reviewSite = undefined;
            return reviewWebLinks;
          })
          .map((item) => {
            item.reviewId = createdReview.id;
            return item;
          })
      )
    );
  }
  if (review.reviewTags && review.reviewTags.length > 0) {
    tasks.push(
      reviewService.setReviewTags(
        createdReview.id,
        review.reviewTags.map((reviewTag) => reviewTag.reviewTagTypeId)
      )
    );
  }
  if(review.reviewQuestions && review.reviewQuestions.length>0){
    tasks.push(
      reviewService.setReviewQuestions(
        createdReview.id,
        review.reviewQuestions
      )
    );
  }

  await Promise.all(tasks);

  await manualEmailSendingService.sendNewNegativeReviewEmail(createdReview.id);

  return createdReview;
};

export default addReview;
