import { QuerySort, QuerySortArr, SCondition } from "@nestjsx/crud-request";
import { ServiceOrder } from "services/service-order.service";
import { PagiantedResponse } from "services/interfaces/paginated-response.interface";
import { serviceOrderService } from "services";

const fetchServiceOrders = async (
  page: number,
  limit: number,
  sort?: QuerySort | Array<QuerySort>,
  search: SCondition = {}
) => {
  const validSort: Array<QuerySort | QuerySortArr> = [];

  validSort.push({ field: "linkedToReviewTimes", order: "ASC" });

  if (Array.isArray(sort)) {
    validSort.push(...sort);
  } else if (sort) {
    validSort.push(sort);
  }

  validSort.push({ field: "id", order: "DESC" });

  if (search.$and) {
    const today = new Date();
    today.setDate(today.getDate() - 7);
    today.setHours(0, 0, 0, 0);

    search.$and.push({ inBlacklist: { $eq: false } });
    search.$and.push({ "reviewServiceOrders.id": { $isnull: true } });
    search.$and.push({
      $or: [{ latestCallAt: { $isnull: true } }, { latestCallAt: { $lt: today.toISOString() } }],
    });
  }

  const serviceOrders = await serviceOrderService.findAll({
    fields: [
      "id",
      "number",
      "branchName",
      "clientDisplayName",
      "clientPhone",
      "carManufacturer",
      "carModel",
      "carManufactureYear",
      "closedAt",
      "createdAt",
      "linkedToReviewTimes",
      "inBlacklist",
      "latestCallAt",
      "manager",
      "managerForPayment",
      "managerPassed",
      "dateForPayment",
      "datePassed",

    ],
    join: [["reviewServiceOrders", ["id"]]],
    page,
    limit,
    sort: validSort,
    search,
  });

  return serviceOrders as PagiantedResponse<ServiceOrder>;
};

export default fetchServiceOrders;
